import React from "react"
import {Modal} from "antd"
import {SetEmailForm} from "./SetEmailForm"

type Props = {
  visible: boolean
  onUpdateEmail: (email?: string) => void
  validateEmail: (email: string) => Promise<string | undefined>
  onClose: () => void
}
export const UpdateEmailDialog: React.FC<Props> = ({
  visible,
  onUpdateEmail,
  validateEmail,
  onClose
}): React.ReactElement => {
  return <Modal
    title="Update Email"
    visible={visible}
    onCancel={onClose}
    footer={null}>
    <SetEmailForm
      onSubmit={onUpdateEmail}
      validateEmail={validateEmail}
    />
  </Modal>
}