import React from "react"
import {Button, Popconfirm, Table} from "antd"
import {UserDevice} from "./models/user-device"

type Props = {
  userDevices: UserDevice[],
  onRemoveDevice: (userDevice: UserDevice) => void
}

export const UserDeviceTable: React.FC<Props> = ({
  userDevices,
  onRemoveDevice,
}): React.ReactElement => {
  const userDeviceColumns = [
    {title: "Client Name", dataIndex: "clientName"},
    {title: "Registration Date", dataIndex: "registrationDate"},
    {
      render: (text: string, userDevice: UserDevice) => <Popconfirm
        title="Are you sure you want to deauthorize this device?, user will be asked for OTP Verification again"
        onConfirm={() => onRemoveDevice(userDevice)}>
        <Button
          style={{marginLeft: 10}}
          type="primary"
          size="small"
          danger
        >
          Deauthorize
        </Button>
      </Popconfirm>
    }
  ]
  return <Table dataSource={userDevices} columns={userDeviceColumns}/>
}