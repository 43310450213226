import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {User, UserService, UserType} from "../../../services/user-service"
import {Button, Card, Col, Descriptions, message, Row, Space, Spin, Switch, Typography} from "antd"
import {GroupService, GroupWithSubgroups} from "../../../services/group-service"
import {GroupSelectorV2} from "./components/GroupSelectorV2"
import {SetTemporaryPasswordDialog} from "./SetTemporaryPasswordDialog"
import {UpdateEmailDialog} from "./components/UpdateEmailDialog"
import {RegistrationService} from "../../../services/registration-service"
import {UserDeviceService} from "../../../services/user-device-service"
import {UpdateLdapQueryDialog} from "./components/UpdateLdapQueryDialog"
import {UserDeviceTable} from "./components/UserDeviceTable"
import {UserDevice} from "./components/models/user-device"

type UserGroupState = {
  loading: boolean
  userGroups: string[]
}

export const UserDetail: React.FC = (): React.ReactElement => {
  const { userId } = useParams()
  const { id24Axios } = useId24()
  const [user, setUser] = useState<User | null>(null)
  const [organizationGroups, setOrganizationGroups] = useState<GroupWithSubgroups[]>([])
  const [userGroupState, setUserGroupState] = useState<UserGroupState>({ loading: true, userGroups: [] })
  const [updatingUserActiveStatus, setUpdatingUserActiveStatus] = useState<boolean>(false)
  const [showSetPasswordDialog, setShowSetPasswordDialog] = useState<boolean>(false)
  const [showUpdateEmailDialog, setShowUpdateEmailDialog] = useState<boolean>(false)
  const [showUpdateLdapQueryDialog, setShowUpdateLdapQueryDialog] = useState<boolean>(false)
  const [userDevices, setUserDevices] = useState<UserDevice[]>([])

  const userService = UserService(id24Axios())
  const groupService = GroupService(id24Axios())
  const registrationService = RegistrationService(id24Axios())
  const userDeviceService = UserDeviceService(id24Axios())

  useEffect(() => {
    if(userId) {
      userService
        .getUserById(userId)
        .then(userResponse => setUser(userResponse))
      userService
        .getUserGroups(userId)
        .then(userGroupsResponse =>
          setUserGroupState({
            loading: false,
            userGroups: userGroupsResponse.map(group => group.id)
          }))
        .catch(() => { setUserGroupState({ ...userGroupState, loading: false })})
      loadUserDevices()
    }
  }, [])

  useEffect(() => {
    groupService
      .getGroupTree()
      .then(groups => setOrganizationGroups(groups))
  }, [])

  const onUserGroupChanged = (groupIds: string[]) => {
    setUserGroupState({
      ...userGroupState,
      userGroups: groupIds
    })
  }

  const loadUserDevices = () => userDeviceService
    .getUserDevices(userId as string)
    .then(userDevicesResponse => setUserDevices(userDevicesResponse))

  const onSaveUserGroup = () => {
    userService
      .assignUserToGroups(userId as any, userGroupState.userGroups)
      .then(() => message.success("User has been assigned to group"))
      .catch(() => message.error("Assign user to group failed"))
  }

  const updateUserActiveStatus = (userActiveValue: boolean) => {
    if(user) {
      setUpdatingUserActiveStatus(true)
      const operation = userActiveValue ? "Activ" : "Deactiv"
      userService
        .updateUserActiveStatus(userId as any, userActiveValue)
        .then(() => {
          setUser({
            ...user,
            active: userActiveValue
          })
          setUpdatingUserActiveStatus(false)
          return message.success(`User ${operation}ated`)
        })
        .catch((errorMessage) => {
          setUpdatingUserActiveStatus(false)
          return message.error(`${operation}ating user failed: ${errorMessage}`)
        })
    }
  }

  const setPassword = (password: string) => {
    userService
      .updateUserPassword(userId as string, password)
      .then((errors) => {
        if(errors.length > 0) {
          return message.error({
            content: errors.map(error => (<><>{ error }</><br/></>))
          })
        }
        setShowSetPasswordDialog(false)
        return message.success("User's temporary password updated")
      })
  }

  const validateUserEmail = async (email: string, type: UserType): Promise<string | undefined> =>
    registrationService
      .validateUserEmail(email, type)

  const onUpdateEmail = (email?: string) => {
    userService
      .updateUserEmail(userId as string, email)
      .then(errors => {
        if(errors.length > 0) {
          return message.error({
            content: errors.map(error => (<><>{ error }</><br/></>))
          })
        }
        setShowUpdateEmailDialog(false)
        setUser(user && {
          ...user,
          email: email || "",
        })
        return message.success("User email updated")
      })
  }

  const onUpdateLdapQuery = (ldapQuery: string) =>
    userService
      .updateUserLdapQuery(userId as string, ldapQuery)
      .then(() => {
        setShowUpdateLdapQueryDialog(false)
        setUser(user && {
          ...user,
          ldapQuery
        })
        return message.success("User's organization unit updated")
      })
      .catch(() => message.error("User's organization update failed"))

  const onRemoveDevice = (userDevice: UserDevice) => {
    userDeviceService
      .deleteUserDevice(userDevice.clientId, userId as string)
      .then(() => {
        loadUserDevices()
        return message.success("User Deauthorized")
      })
      .catch(() => message.error("User Deauthorization failed"))
  }

  if(user === null) {
    return <><Row>
      <Space size="middle">
        <Spin size="large"/>
      </Space>
    </Row></>
  }
  return <>
    <Row>
      <Col>
        <Typography.Title>{ user.username }</Typography.Title>
      </Col>
    </Row>

    <Row>
      <Col span="24">
        <Card>
          <Descriptions title="User Info" bordered style={{ textAlign: "left"}}>
            <Descriptions.Item label="User Type" span={3}>{ user.type.toUpperCase() }</Descriptions.Item>
            {
              user.type === UserType.Local
                ? <>
                  <Descriptions.Item label="Email" span={3}>
                    { user.email }<br/>
                    <Button onClick={() => setShowUpdateEmailDialog(true)}>Update Email</Button>
                  </Descriptions.Item>
                  <Descriptions.Item label="Password" span={3}>
                    <Button onClick={() => setShowSetPasswordDialog(true)}>
                      Set Temporary Password
                    </Button>
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label="LDAP Query" span={3}>
                    { user.ldapQuery || "" }<br/>
                    <Button onClick={() => setShowUpdateLdapQueryDialog(true)}>Update LDAP Query</Button>
                  </Descriptions.Item>
                </>
            }
            <Descriptions.Item label="Active" span={3}>
              <Switch
                loading={updatingUserActiveStatus}
                checked={user.active}
                onChange={(checked) => updateUserActiveStatus(checked)}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Assigned Groups" span={3}>
              {
                userGroupState.loading
                  ? <>Loading...</>
                  : <>
                    <GroupSelectorV2
                      groups={organizationGroups}
                      onChange={onUserGroupChanged}
                      initialGroups={userGroupState.userGroups}/>
                    <Button onClick={onSaveUserGroup}>Save</Button>
                  </>
              }
            </Descriptions.Item>
            <Descriptions.Item label="User Devices" span={3}>
              <UserDeviceTable userDevices={userDevices} onRemoveDevice={onRemoveDevice}/>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
    <Row>
      <SetTemporaryPasswordDialog
        visible={showSetPasswordDialog}
        onSetPassword={setPassword}
        onClose={() => setShowSetPasswordDialog(false)}
      />
      <UpdateEmailDialog
        visible={showUpdateEmailDialog}
        onUpdateEmail={onUpdateEmail}
        validateEmail={email => validateUserEmail(email, user?.type)}
        onClose={() => setShowUpdateEmailDialog(false)}
      />
      <UpdateLdapQueryDialog
        visible={showUpdateLdapQueryDialog}
        initialLdapQuery={user.ldapQuery}
        onUpdateLdapQuery={onUpdateLdapQuery}
        onClose={() => setShowUpdateLdapQueryDialog(false)}
      />
    </Row>
  </>
}

