import React from "react"
import {Tooltip, Table} from "antd"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import {ColumnsType} from "antd/lib/table/interface"
import {Client} from "../../../../models/client"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

type Props = {
  clients: Client[]
  onEdit: (clientId: string, client: Client) => void
  onDelete: (clientId: string) => void
  onSelect: (client: Client) => void
  loading: boolean
}

export const ClientTable: React.FC<Props> = ({
  clients,
  onEdit,
  onDelete,
  onSelect,
  loading
}): React.ReactElement => {
  const columns: ColumnsType<Client> = [
    {
      title: "Id",
      key: "id",
      width: "100px",
      render: (client: Client) => {
        return <a onClick={() => onSelect(client)}>{client.id.substring(0,5)}...</a>
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "150px"
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "200px",
    },
    {
      title: "Type",
      key: "type",
      width: "150px",
      align: "center",
      render: (data: Client) => data.info.type
    },
    {
      title: "RedirectUrls",
      key: "redirectUrls",
      width: "200px",
      render: (data: Client) => data.info.redirectUrls.join(";")
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      fixed: "right" as any,
      align: "center",
      render: (client: Client) => (
        <div>
          <Tooltip placement="top" title="Edit">
            <EditOutlined style={{ color : "#1890ff" , fontSize: "20px"}} onClick={(e) => {
              e.stopPropagation()
              onEdit(client.id, client)
            }}/>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="top" title="Delete">
            <DeleteOutlined style={{ color : "red" , fontSize: "20px"}}  onClick={(e) => {
              e.stopPropagation()
              onDelete(client.id)
            }}/>
          </Tooltip>
        </div>

      )
    }
  ]

  return <Table
    rowKey="id"
    dataSource={clients}
    columns={columns}
    loading={loading}
    scroll={{ x: 1400 }}
    footer={() => `Total ${clients.length} Row`}
  />
}
