import {INavigator} from "./INavigator"
import {AuditLog} from "../../../../models/audit-logs/audit-log"
import {AuditLogEventType} from "../../../../models/audit-logs/audit-log-event-type"
import {
  LoginFailAuditLog,
  LoginFailInfo,
  LoginFailInvalidPassword,
  LoginFailLDAPAuthenticationFailed,
  LoginFailReason,
  LoginFailRedirectUrl,
  LoginFailUserInactive,
  LoginFailUsernameNotFound
} from "../../../../models/audit-logs/login-fail-audit-log"
import React from "react"
import {
  AccountActivity,
  AccountActivityAuditLogData, AccountActivityUserActive,
  AccountActivityUserCreated
} from "../../../../models/audit-logs/account-activity-audit-log"

type Props = {
  navigator: INavigator,
  message: string,
  auditLog: { activity: AuditLogEventType } & AuditLog,
}

export const AuditLogMessage: React.FC<Props> =
  ({ navigator, auditLog }): React.ReactElement => {
    if ((auditLog as LoginFailAuditLog).activity === AuditLogEventType.LoginFailed) {
      const {reason} = auditLog.info as LoginFailInfo
      if (reason === LoginFailReason.InvalidRedirectUrl) {
        const info = auditLog.info as LoginFailRedirectUrl
        return <>There is an attempt to login to client <a
          onClick={() => navigator.goToClient(info.clientId)}>{info.clientId}
        </a> with redirect url <a>{info.redirectUrl}</a></>
      } else if (reason === LoginFailReason.UsernameNotFound) {
        const info = auditLog.info as LoginFailUsernameNotFound
        return <>There is an attempt to login to client <a
          onClick={() => navigator.goToClient(info.clientId)}>{info.clientId}
        </a> with username <b>{info.username}</b></>
      } else if (reason === LoginFailReason.InvalidPassword) {
        const info = auditLog.info as LoginFailInvalidPassword
        return <>There is an attempt to login to client <a
          onClick={() => navigator.goToClient(info.clientId)}>{info.clientId}
        </a> with incorrect password by <a onClick={() => navigator.goToUser(info.userId)}>{info.userId}</a></>
      } else if (reason === LoginFailReason.UserInactive) {
        const info = auditLog.info as LoginFailUserInactive
        return <>There is an attempt to login to client <a
          onClick={() => navigator.goToClient(info.clientId)}>{info.clientId}
        </a> while <a onClick={() => navigator.goToUser(info.userId)}>{info.userId}</a> is inactive</>
      } else if (reason === LoginFailReason.LDAPAuthenticationFailed) {
        const info = auditLog.info as LoginFailLDAPAuthenticationFailed
        return <>There is an attempt to login to client <a
          onClick={() => navigator.goToClient(info.clientId)}>{info.clientId}
        </a> with username <b>{info.username}</b></>
      } else {
        return <>There is an attempt to login</>
      }
    } else if (auditLog.activity === AuditLogEventType.AccountActivity) {
      const { info: { activity } } = auditLog as AccountActivityAuditLogData
      if(activity === AccountActivity.UserCreated) {
        const info = auditLog.info as AccountActivityUserCreated
        return info.adminUserId
          ? <>Admin user <a onClick={() => navigator.goToUser(info.adminUserId as string)}>{info.adminUserId}</a> created new user <a onClick={() => navigator.goToUser(info.userId)}>{info.userId}</a></>
          : <>User <a onClick={() => navigator.goToUser(info.userId)}>{info.userId}</a> was created</>
      } else if (activity === AccountActivity.SetUserActive) {
        const info = auditLog.info as AccountActivityUserActive
        const activeText = info.active ? "active" : "inactive"
        return info.adminUserId
          ? <>Admin user <a onClick={() => navigator.goToUser(info.adminUserId as string)}>{info.adminUserId}</a> set user <a onClick={() => navigator.goToUser(info.userId)}>{info.userId}</a> to {activeText}</>
          : <>User <a onClick={() => navigator.goToUser(info.userId)}>{info.userId} was set to {activeText}</a></>
      }
    }
    return <>{ auditLog.message }</>
  }