import React, {useEffect, useState} from "react"
import {Button, Card, Col, Modal, notification, Row, Typography} from "antd"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {OrganizationTable} from "./components/OrganizationTable"
import {AddingOrganizationData, EditOrganization} from "./components/EditOrganization"
import {
  LoginSettingType,
  Organization,
  OrganizationService,
  OrganizationStatus
} from "../../../services/organization-service"

const { Title } = Typography

export const OrganizationManagement: React.FC = (): React.ReactElement => {
  const { id24Axios } = useId24()
  const [addOrganizeModalVisible, setAddOrganizeModalVisible] = useState<boolean>(false)
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const organizationService = OrganizationService(id24Axios())

  useEffect(() => {
    reloadOrganizations()
  }, [])

  const reloadOrganizations = () => {
    setLoading(true)
    organizationService
      .getOrganizations()
      .then(organizations => {
        setOrganizations(organizations)
        setLoading(false)
      })
  }

  const onConfirmAddOrganization = async (organization: AddingOrganizationData) => {
    const response = await organizationService.createOrganizations({
      name: organization.name,
      description: organization.description,
      email: organization.email,
      username: organization.username,
      status: OrganizationStatus.Active,
      password: "",
      employeeId: "",
      loginSettings: [
        { type: LoginSettingType.Classic },
        ...(organization.phoneVerification ? [{ type: LoginSettingType.DeviceVerification }] : [])
      ]
    })
    if (response==="success") {
      notification.success({message: "success"})
      setAddOrganizeModalVisible(false)
      reloadOrganizations()
    } else {
      notification.error({ message: "Error" })
    }
  }
  return <>
    <Row>
      <Col>
        <Title>Organization</Title>
      </Col>
    </Row>

    <Row>
      <Col span="24">
        <Card>
          <Row justify="end">
            <Col>
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => setAddOrganizeModalVisible(true)}
                type="primary"
              >
                +Add organization
              </Button>
            </Col>
          </Row>
          <br />
          <Modal
            title="Add Organization"
            visible={addOrganizeModalVisible}
            onCancel={() => setAddOrganizeModalVisible(false)}
            footer={null}
          >
            <EditOrganization
              submitLabel="Add"
              onConfirm={onConfirmAddOrganization}
            />
          </Modal>
          <OrganizationTable
            organizations={organizations}
            loading={loading}
          />
        </Card>
      </Col>
    </Row>
  </>
}
