import React from "react"
import {Table} from "antd"
import {User} from "../../../../services/user-service"

type Props = {
  users: User[],
  onSelect: (user: User) => void
  loading: boolean
}

export const UserTable: React.FC<Props> = ({
  users,
  onSelect,
  loading
}): React.ReactElement => {
  const userColumns = [
    { title: "Id", key: "id", width: "100px", render: (user: User) => <a onClick={() => onSelect(user)}>{ user.id.substring(0, 5) }...</a> },
    { title: "Username", dataIndex: "username", key: "username" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Email", dataIndex: "email", key: "email" }
  ]
  return <Table
    rowKey="id"
    dataSource={users}
    columns={userColumns}
    loading={loading}
    scroll={{ x: 1400 }}
    footer={() => `Total ${users.length} Row`}
  />
}