import {AxiosInstance} from "axios"
import {MyProfile} from "../models/my-profile"

type ResetPasswordLinkData = {
  resetPasswordLink: string
  otp: string
  id: string
}

type IMyAccountService = {
  setPassword: (newPassword: string, setPasswordToken: string) => Promise<string[]>
  requestResetPassword: (clientId: string, email: string) => Promise<ResetPasswordLinkData>
  setPasswordWithOTP: (link: string, otp: string, password: string) => Promise<string[]>
  getProfile: () => Promise<MyProfile>
  setPasswordWithOldPassword: (newPassword: string, oldPassword: string, setPasswordToken: string) => Promise<void>
}

export const MyAccountService = (axiosInstance: AxiosInstance): IMyAccountService => {
  const apiBaseUrl = "/my-account/api/v1"
  return ({
    setPassword: (newPassword: string, setPasswordToken: string) =>
      axiosInstance
        .post(`${apiBaseUrl}/set-password`, {newPassword}, {
          headers: {
            Authorization: `Bearer ${setPasswordToken}`
          }
        })
        .then(() => [])
        .catch(error => {
          if(error.response.status === 400) {
            return error.response.data.reasons
          }
          return ["Something wrong"]
        }),
    requestResetPassword: (clientId: string, email: string) =>
      axiosInstance
        .post(
          `${apiBaseUrl}/request-reset-password`,
          {clientId, email}
        )
        .then(response => response.data),
    setPasswordWithOTP: (linkId: string, otp: string, password: string) =>
      axiosInstance
        .post(`${apiBaseUrl}/reset-with-link`, { linkId, otp, password })
        .then(() => [])
        .catch(error => {
          if(error.response.status === 400) {
            return error.response.data.reasons
          }
          return ["Something wrong"]
        }),
    getProfile: async () =>
      axiosInstance
        .get(`${apiBaseUrl}/profile`)
        .then(response => response.data),
    setPasswordWithOldPassword: async (newPassword: string, oldPassword: string, setPasswordToken: string) => {
      await axiosInstance
        .post(
          `${apiBaseUrl}/update-password`,
          {newPassword, oldPassword},
          { headers: { Authorization: `Bearer ${setPasswordToken}` } })
    }
  })
}