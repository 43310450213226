import "./why-did-you-render"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App, {AuthenticationHelper} from "./App"
import reportWebVitals from "./reportWebVitals"
import axios from "axios"
import {loadAppConfig} from "./config/app-config"
import {AuthService} from "./services/auth-service"
import {Id24, Id24State} from "./drivers/id24/Id24";

(async () => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  )

  try {
    const appConfig = loadAppConfig()
    const {authUrl, clientId} = appConfig
    const id24 = Id24(
      window,
      localStorage,
      AuthService(axios.create({
        baseURL: authUrl,
        withCredentials: true,
      })),
      authUrl,
      clientId
    )
    const id24Instance = await id24.init()
    const { authorize, renewAccessToken, logout, reloadPage } = id24
    const authenticationHelper: AuthenticationHelper = {
      authorize,
      renewAccessToken: () =>
        renewAccessToken()
          .then(id24Instance => {
            return id24Instance.state === Id24State.Authorized ? {
              tokenAccess: id24Instance.tokenAccess,
              rawAccessToken: id24Instance.rawAccessToken }
              : null
          })
          .catch(() => { return null }),
      logout,
      reloadPage,
    }

    root.render(
      <React.StrictMode>
        <App id24Instance={id24Instance} authenticationHelper={authenticationHelper}/>
      </React.StrictMode>
    )

  } catch (e) {
    console.log(e)
  }
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
