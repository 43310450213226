import {SideMenuItem} from "./SideMenuItem"
import {HomeOutlined} from "@ant-design/icons"
import React from "react"

export const superAdminMenu: SideMenuItem[] = [
  {
    label: "Organization",
    icon: <HomeOutlined/>,
    link: "/main/organizations"
  }
]