import React from "react"
import {Tooltip, Table} from "antd"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

type ClientRoles= {
  id: string
  name: string
  description: string
}

type Props = {
  clientRoles: ClientRoles[],
  onEdit: (roleId: string, clientRoles: ClientRoles) => void,
  onDelete: (roleId: string) => void,
  loading: boolean
}

export const ClientRolesTable: React.FC<Props> = ({
  clientRoles,
  onDelete,
  onEdit,
  loading
}): React.ReactElement => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "100px",
      render: (id: string) => `${id.substring(0,5)}...`
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "200px",
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      fixed: "right" as any,
      render: (data: ClientRoles) => (
        <div>
          <Tooltip placement="top" title="Edit">
            <EditOutlined style={{ color : "#1890ff" , fontSize: "20px"}} onClick={(e) => {
              e.stopPropagation()
              onEdit(data.id, data)
            }}/>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="top" title="Delete">
            <DeleteOutlined style={{ color : "red" , fontSize: "20px"}}  onClick={(e) => {
              e.stopPropagation()
              onDelete(data.id)
            }}/>
          </Tooltip>
        </div>

      )
    }
  ]

  return <>
    <Table
      rowKey="id"
      dataSource={clientRoles}
      columns={columns}
      loading={loading}
      scroll={{ x: 500 }}
      footer={() => `Total ${clientRoles.length} Row`}
    />
  </>
}
