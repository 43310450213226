import React, {useEffect, useState} from "react"
import {Button, Col, Image, Layout, Row} from "antd"
import {SideMenu, SideMenuGroup} from "./components/side-menu/SideMenu"
import logo from "./images/ddebt_logo.png"
import {MainRoute} from "./components/MainRoute"
import "antd/dist/antd.css"
import {MainFooter} from "./components/MainFooter"
import {MainHeader, MainMenuItem} from "./components/MainHeader"
import {AccessReader} from "../../drivers/id24/access-reader"
import {useId24} from "../../drivers/id24/Id24Provider"
import {MyAccountService} from "../../services/my-account-service"
import {useNavigate} from "react-router-dom"

const {Sider, Content} = Layout

export const Main: React.FC = (): React.ReactElement => {
  const {authenticated, tokenAccess, login, logout, id24Axios} = useId24()
  const [collapse, setCollapse] = useState(false)
  const [profile, setProfile] = useState({
    username: "admin",
    email: "admin@id24.com"
  })
  const [userRequestedLogout, setUserRequestedLogout] = useState(false)
  const navigate = useNavigate()


  useEffect(() => {
    if(authenticated) {
      MyAccountService(id24Axios())
        .getProfile()
        .then(profileResponse => setProfile(profileResponse))
    }
  }, [authenticated])

  if(!authenticated) {
    if(userRequestedLogout) {
      login(window.location.href, true)
    }
    else {
      login(window.location.href, false)
    }

    return <Button onClick={() => login(window.location.href, false)}>Login</Button>
  }

  const sideMenuGroups = (tokenAccess && AccessReader(tokenAccess.userAccess).getMenuSet()) || []
  const defaultRoute = sideMenuGroups.includes(SideMenuGroup.OrganizationAdmin) ? "/main/users" : "/main/organizations"
  if(sideMenuGroups.length === 0) {
    navigate("/unauthorized")
  }
  return <>
    <Layout>

      <Sider
        className="site-layout-background"
        trigger={null}
        collapsible
        collapsed={collapse}
        style={{backgroundColor: "white"}}
      >
        <Row className="header-logo">
          <Col style={{
            textAlign: "center",
            margin: "15px",
          }}><Image preview={false} src={logo}/></Col>
        </Row>
        <SideMenu groups={sideMenuGroups}/>
      </Sider>

      <Layout className="site-layout">
        <MainHeader
          collapse={collapse}
          onCollapse={setCollapse}
          onMenuClicked={(item: MainMenuItem) => {
            if(item === MainMenuItem.Logout) {
              setUserRequestedLogout(true)
              logout()
                .then(() => login(window.location.href, false))
            }
          }}
          username={profile.username}/>

        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: "24px 16px",
            minHeight: 280,
          }}
        >
          <MainRoute defaultRoute={defaultRoute}/>
        </Content>

        <MainFooter/>
      </Layout>
    </Layout>
  </>
}
