import {SideMenuItem} from "./SideMenuItem"
import {ClusterOutlined, GroupOutlined, SecurityScanOutlined, UserOutlined} from "@ant-design/icons"
import React from "react"

export const organizationAdminMenu: SideMenuItem[] = [
  {
    label: "Users",
    icon: <UserOutlined/>,
    link: "/main/users",
  },
  {
    label: "Groups",
    icon: <GroupOutlined/>,
    link: "/main/groups",
  },
  {
    label: "Client",
    icon: <ClusterOutlined/>,
    link: "/main/clients",
  },
  {
    label: "Audit Logs",
    icon: <SecurityScanOutlined/>,
    link: "/main/audit-logs",
  }
]