import React from "react"
import {SetPasswordForm} from "./components/SetPasswordForm"
import {useId24} from "../../drivers/id24/Id24Provider"
import {MyAccountService} from "../../services/my-account-service"
import {useNavigate} from "react-router-dom"
import {WelcomeAccountPage} from "./components/WelcomeAccountPage"
import {message} from "antd"
import {loadAppConfig} from "../../config/app-config"

type Props = {
  loginPageTitle: {
    title: string
  }
}

export const ResetPassword: React.FC<Props> = ({loginPageTitle}): React.ReactElement => {
  const { id24Axios } = useId24()
  const { apiBaseUrl } = loadAppConfig()
  const navigate = useNavigate()

  const myAccountService = MyAccountService(id24Axios(apiBaseUrl))

  const onResetPassword = async (password: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const { id: linkId, otp } = Object.fromEntries(urlSearchParams.entries())
    myAccountService
      .setPasswordWithOTP(linkId, otp, password)
      .then(errors => {
        if(errors.length > 0) {
          message.error({
            content: errors.map(error => (<><>{ error }</><br/></>))
          })
        } else {
          navigate("/reset-password-completed")
        }
      })
  }

  return <WelcomeAccountPage>
    <SetPasswordForm onSetPassword={onResetPassword} loginPageTitle={loginPageTitle}/>
  </WelcomeAccountPage>
}