import React from "react"
import {Menu} from "antd"
import {Link} from "react-router-dom"
import {organizationAdminMenu} from "./OrganizationAdminMenu"
import {superAdminMenu} from "./SuperAdminMenu"
import {SideMenuItem} from "./SideMenuItem"

export enum SideMenuGroup {
  OrganizationAdmin = "organizationAdmin",
  SuperAdmin = "superAdmin"
}

interface Props {
  groups: SideMenuGroup[]
}

export const SideMenu: React.FC<Props> = ({groups}): React.ReactElement => {

  const sideMenuItems = groups.map(group => {
    return group === SideMenuGroup.OrganizationAdmin ? organizationAdminMenu : superAdminMenu
  }).flat()
  
  const toItems = ({label, link, icon}: SideMenuItem) => ({
    label: <Menu.Item key={link} icon={icon}>
      <Link to={link}>{label}</Link>
    </Menu.Item>,
    key: label
  })

  return <Menu
    defaultOpenKeys={["sub1"]}
    style={{height: "100%", borderRight: 0}}
    mode="inline"
    items={sideMenuItems.map(toItems)}
  />
}
