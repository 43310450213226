import React, {useState} from "react"

type Props = {
  onSubmit: (email: string) => void
  loginPageTitle: {
    title: string
  }
}
export const ForgetPasswordForm: React.FC<Props> = ({onSubmit, loginPageTitle}) => {

  const [email, setEmail] = useState<string>("")

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      onSubmit(email)
    }
  }

  return <>
    <div className="form-layout">
      <div className="form">
        <div className="detail">
          <p className="header-gsb-pink" style={{textAlign: "center"}}>{ loginPageTitle.title }</p>
          <p className="header" style={{textAlign: "center"}}>ระบบบันทึกข้อมูลสำหรับบริษัทติดตามหนี้</p>
        </div>
        <div style={{fontWeight: 500, fontSize: "18px"}} className="mb-3">
          <label htmlFor="txtUsername">Email
            <input
              type="email"
              className="input"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              id="txtEmail"
              placeholder="youremail@yourorg.com"
              onKeyPress={handleKeyPress}
            />
          </label>

        </div>

        <div className="mb-5">
          <button type="button" onClick={() => onSubmit(email)} className="button">Reset Password</button>
          <br/>
        </div>
        <br/>
      </div>
    </div>
  </>
}