import React from "react"
import {Modal} from "antd"
import {PasswordForm} from "../../welcome/components/PasswordForm"

type Props = {
  visible: boolean
  onSetPassword: (newPassword: string) => void
  onClose: () => void
}
export const SetTemporaryPasswordDialog: React.FC<Props> = ({
  visible,
  onSetPassword,
  onClose
}): React.ReactElement => {
  return <Modal
    title="Set Temporary Passsword"
    visible={visible}
    onCancel={onClose}
    footer={null}>
    <PasswordForm onSubmit={onSetPassword}/>
  </Modal>
}