import React from "react"

type Props = {
  clientName?: string
  onContinue: () => void
  onDeAuthorize: () => void
  loginPageTitle: {
    title: string
  }
}
export const LoggedInCTA: React.FC<Props> = ({
  clientName,
  onContinue,
  onDeAuthorize,
  loginPageTitle,
}) => <div className="form-layout">
  <div className="form">
    <div className="detail">
      <p className="header-gsb-pink" style={{textAlign: "center"}}>{ loginPageTitle.title }</p>
      <p className="header" style={{textAlign: "center"}}>ระบบบันทึกข้อมูลสำหรับบริษัทติดตามหนี้</p>
    </div>

    <div className="mb-5">
      <button type="button" onClick={onContinue} className="button">Continue{ clientName && ` (${clientName})` }</button>
      <br/>
      <button type="button" onClick={onDeAuthorize} className="button">Log out</button>
    </div>
  </div>
</div>