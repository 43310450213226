import React, {useEffect} from "react"
import {Button, Form, Input, Switch} from "antd"

interface Props {
  submitLabel: string;
  onConfirm: (organization: AddingOrganizationData) => void
}

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16}
}

const tailLayout = {
  wrapperCol: {offset: 8, span: 16},
}

export type AddingOrganizationData = {
  name: string
  description: string
  email: string
  username: string
  phoneVerification: boolean
}

const emptyOrganizationData: AddingOrganizationData = {
  name: "",
  description: "",
  email: "",
  username: "admin",
  phoneVerification: false
}

export const EditOrganization: React.FC<Props> = ({
  submitLabel,
  onConfirm
}): React.ReactElement => {
  const [form] = Form.useForm<AddingOrganizationData>()
  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(emptyOrganizationData)
  }, [])

  return (
    <>
      <Form {...layout} form={form} colon={false} onFinish={onConfirm}>

        <Form.Item name="name" label="Name"
          rules={[{required: true, message: "Organization name is required."}]}>
          <Input/>
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input/>
        </Form.Item>

        <Form.Item name="email" label="Admin Email"
          rules={[
            { type: "email", message: "The input is not valid E-mail!" },
            { required: true, message: "Please input your E-mail!" },
          ]}>
          <Input/>
        </Form.Item>

        <Form.Item name="username" label="Username"
          rules={[{required: true, message: "Username is required."}]}
        >
          <Input disabled/>
        </Form.Item>

        <Form.Item name="phoneVerification" label="Verify Phone" valuePropName="checked">
          <Switch/>
        </Form.Item>

        <Form.Item
          {...tailLayout}
        >
          <Button type="primary" htmlType="submit">
            {submitLabel}
          </Button>
        </Form.Item>

      </Form>
    </>
  )
}
