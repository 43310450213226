import React from "react"
import {Tooltip, Table, Tag} from "antd"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import {Organization, OrganizationStatus} from "../../../../services/organization-service"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

type Props = {
  organizations: Organization[],
  loading: boolean
}

export const OrganizationTable: React.FC<Props> = ({organizations , loading}): React.ReactElement => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "18%",
    },
    {
      title: "Admin Email",
      dataIndex: "email",
      key: "email",
      width: "18%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "18%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "18%",
      render: (status: OrganizationStatus) => {
        if (status === OrganizationStatus.Active)
          return <Tag color='green' style={{width: 60, textAlign: "center"}}>Active</Tag>
        if (status === OrganizationStatus.Pending){
          return <Tag style={{width: 60, textAlign: "center"}}>Pending</Tag>
        }
        return  <Tag style={{width: 60, textAlign: "center"}}>Deleted</Tag>
      }
    },
    {
      title: "Created At",
      dataIndex: "createdDate",
      key: "createdDate",
      width: "18%",
      render : (createdDate: string) => dayjs(createdDate).format("YYYY-MM-DD HH:mm")
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "18%",
    },
    {
      title: "Action",
      key: "action",
      width: "8%",
      fixed: "right" as any,
      render: (data: Organization) => (
        <div>
          <Tooltip placement="top" title={"Edit"}>
            <EditOutlined style={{ color : "#1890ff" , fontSize: "20px"}} onClick={(e) => {
              e.stopPropagation()
              // onEdit(data.id, data)
            }}/>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="top" title={"Delete"}>
            <DeleteOutlined style={{ color : "red" , fontSize: "20px"}}  onClick={(e) => {
              e.stopPropagation()
              // onDelete(data.id)
            }}/>
          </Tooltip>
        </div>
      )
    }
  ]

  return <>
    <Table
      rowKey="id"
      dataSource={organizations}
      columns={columns.slice(0, columns.length -2)}
      loading={loading}
      scroll={{ x: 1400 }}
      footer={() => `Total ${organizations.length} Row`}
    />
  </>
}
