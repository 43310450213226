import React from "react"
import {Card} from "antd"
import {loadAppConfig} from "../../../config/app-config"

type Props = {
  children: React.ReactElement
}

export const WelcomeAccountPage: React.FC<Props> = ({
  children
}): React.ReactElement => {
  const appConfig = loadAppConfig()
  return <>
    <div className="center container-sm login-bg">
      <div
        className="logo"
        style={{
          position: "absolute",
          top: "10px",
          left: "30px",
          width: 137,
          height: 77
        }}/>

      <Card size="small" style={{borderRadius: 16, boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)", marginTop: 70}}
        className="card-login">
        <div className="logo-layout">
          <div className="logo-small-gsb"/>
          <div className="logo-small"/>
        </div>
        <br/>
        {children}
      </Card>

      <div className="footer">
        <div style={{color: "#8A8A8A"}}>version {appConfig.appVersion}</div>
        <br/>
      </div>
    </div>
  </>
}