import React, {useEffect} from "react"
import {Button, Form, Input} from "antd"
import {emailValidationRule} from "../validators/email-validation-rule"

type Props = {
  onSubmit: (email?: string) => void
  validateEmail: (email: string) => Promise<string | undefined>
}

type EmailFormData = {
  email?: string
}

export const SetEmailForm: React.FC<Props> = ({
  onSubmit,
  validateEmail
}): React.ReactElement => {
  const [form] = Form.useForm<EmailFormData>()

  useEffect(() => {
    form.resetFields()
  }, [])

  return <Form form={form} onFinish={({email}) => onSubmit(
    email !== undefined && email.trim().length > 0 ? email.trim() : undefined
  )}>
    <Form.Item
      name="email"
      label="New Email"
      rules={emailValidationRule(validateEmail)}>
      <Input/>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Add
      </Button>
    </Form.Item>
  </Form>
}