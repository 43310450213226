import React from "react"
import "./App.css"
import {BrowserRouter} from "react-router-dom"
import {RootRouter} from "./screens/RootRouter"
import {Id24InstanceProvider} from "./drivers/id24/Id24InstanceProvider"
import {Id24Instance} from "./drivers/id24/Id24"
import {OrganizationUserAccess} from "@enersys/common-auth"

export type TokenInfo = {
  tokenAccess: OrganizationUserAccess
  rawAccessToken: string
}

export type AuthenticationHelper = {
  authorize: (redirectUrl: string, autoLogin: boolean) => void
  renewAccessToken: () => Promise<TokenInfo | null>
  logout: () => Promise<void>
  reloadPage: () => void
}

type Props = {
  id24Instance: Id24Instance
  authenticationHelper: AuthenticationHelper
}

const App: React.FC<Props> = ({ id24Instance, authenticationHelper }) => (
  <div className="App">
    <Id24InstanceProvider instance={id24Instance} authenticationHelper={authenticationHelper}>
      <BrowserRouter>
        <RootRouter/>
      </BrowserRouter>
    </Id24InstanceProvider>
  </div>
)

export default App
