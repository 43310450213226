import React, {useEffect} from "react"
import {Button, Form, Input} from "antd"

export type GroupData = {
  name: string,
  description: string,
  notes: string
}

interface Props {
  initialValue?: GroupData;
  submitLabel: string;
  onError?: (error: Error) => void;
  onConfirm: (groupData: GroupData) => void
}

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16}
}

const tailLayout = {
  wrapperCol: {offset: 8, span: 16},
}

export const EditGroup: React.FC<Props> = ({initialValue, submitLabel, onConfirm}): React.ReactElement => {
  const [form] = Form.useForm<GroupData>()
  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(initialValue || {})
  }, [initialValue])
  const onFinish = (values: any) => {
    onConfirm(values)
  }
  return (
    <>
      <Form {...layout} form={form} colon={false} onFinish={onFinish}>

        <Form.Item name="name" label="Name"
          rules={[{required: true, message: "Name is required."}]}>
          <Input/>
        </Form.Item>

        <Form.Item name="description" label="Description"
        >
          <Input/>
        </Form.Item>

        <Form.Item name="notes" label="Notes"
        >
          <Input/>
        </Form.Item>

        <Form.Item
          {...tailLayout}
        >
          <Button type="primary" htmlType="submit">
            {submitLabel}
          </Button>
        </Form.Item>

      </Form>
    </>
  )
}
