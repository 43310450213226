import React, {useState} from "react"
import {Radio} from "antd"
import {LoginOption} from "../../../models/login-option"

export type Credential = { username: string, password: string };
type Props = {
  clientName?: string
  onLogin: (credential: Credential, rememberMe: boolean) => void
  loginPageTitle: {
    title: string
  }
  onForgetPassword: () => void
  loginOption: LoginOption
  setLoginOption: (option: LoginOption) => void
  loginOptions: LoginOption[]
}
export const LoginForm: React.FC<Props> = ({
  clientName,
  onLogin,
  loginPageTitle,
  onForgetPassword,
  loginOption,
  setLoginOption,
  loginOptions,
}): React.ReactElement => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [credential, setCredential] = useState({username: "", password: ""})
  const [rememberMe, setRememberMe] = useState<boolean>(true)

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      onLogin(credential, rememberMe)
    }
  }

  return <>
    <div className="form-layout">
      <div className="form">
        <div className="detail">
          <p className="header-gsb-pink" style={{textAlign: "center"}}>{ loginPageTitle.title }</p>
          <p className="header" style={{textAlign: "center"}}>ระบบบันทึกข้อมูลสำหรับบริษัทติดตามหนี้</p>
        </div>
        <div style={{fontWeight: 500, fontSize: "18px"}} className="mb-3">
          <label htmlFor="txtUsername">Username
            <input
              type="email"
              className="input"
              value={credential.username}
              onChange={(evt) => setCredential({...credential, username: evt.target.value})}
              id="txtUsername"
              placeholder="Username"

            />
          </label>

        </div>

        <div style={{fontWeight: 500, fontSize: "18px"}} className="mb-4">
          <label htmlFor="txtPassword" className="form-label"
          >Password
            <input
              id="txtPassword"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={credential.password}
              className="input"
              onChange={(evt) => setCredential({...credential, password: evt.target.value})}
              onKeyPress={handleKeyPress}
            />
          </label>

          <div
            id='btnEye'
            aria-label="label"
            className={showPassword ? "eye" : "eyeslash"}
            onClick={() => setShowPassword(!showPassword)}
            onKeyPress={() => setShowPassword(!showPassword)}
          />
        </div>

        <div style={loginOptions.length > 1 ? {} : {display: "none"}}>
          Login mode
          <Radio.Group onChange={evt => setLoginOption(evt.target.value)} value={loginOption} style={{marginLeft: "1rem"}}>
            {
              loginOptions
                .map(loginOption => (<Radio key={loginOption} value={loginOption}>{ loginOption.toUpperCase() }</Radio>))
            }
          </Radio.Group>
        </div>

        <div>
          <input id="chkRemember" type="checkbox" hidden checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}/>
          <label htmlFor="chkRemember" hidden> Remember me</label>
        </div>

        <div className="mb-5">
          <button type="button" onClick={() => onLogin(credential, rememberMe)} className="button">SIGN IN{ clientName && ` (${clientName})` }</button>
          <br/>
          <button hidden={loginOption !== LoginOption.Local} type="button" onClick={onForgetPassword} className="button">Forget Password</button>
        </div>
        <br/>
      </div>
    </div>
  </>
}