import {AxiosInstance} from "axios"
import {GroupWithId} from "./group-service"
import {UserData} from "../screens/main/screens/components/models/user-data"

export enum UserType {
  Local = "local",
  Ldap = "ldap",
}
type UpdateUserGeneralData = {
  username: string
  email: string
  active: boolean
  ldapQuery?: string
}

export type User = {
  id: string
  type: UserType
} & UpdateUserGeneralData

export type UserQuery = {
  limit?: number
  offset?: number
  email?: number
  username?: number
}

export const UserService = (axiosInstance: AxiosInstance) => {
  const apiBaseUrl = "/organization-admin/api/v1"
  return {
    getUsers: (userQuery: UserQuery) => {
      const { username, email } = userQuery
      const query = [
        username && `username=${username}`,
        email && `email=${email}`,
      ].filter(subQuery => subQuery !== undefined).join("&")
      const queryString = query.length > 0 ? `?${query}` : ""
      return axiosInstance
        .get(`${apiBaseUrl}/users${queryString}`)
        .then(result => result.data)
    },
    validateUsername: (username: string) =>
      axiosInstance
        .post(`${apiBaseUrl}/users/username`, { username }),
    createUser: (userData: UserData) =>
      axiosInstance
        .post(`${apiBaseUrl}/users`, userData),
    getUserById: (userId: string) =>
      axiosInstance
        .get(`${apiBaseUrl}/users/${userId}`)
        .then(response => response.data as User),
    updateUserById: (userId: string, updatingUserData: UpdateUserGeneralData) =>
      axiosInstance
        .put(`${apiBaseUrl}/users/${userId}`, updatingUserData)
        .catch(error => {
          throw error.response.data.message
        }),
    updateUserActiveStatus: (userId: string, active: boolean) =>
      axiosInstance
        .put(`${apiBaseUrl}/users/${userId}/active`, { active }),
    updateUserPassword: (userId: string, password: string): Promise<string[]> =>
      axiosInstance
        .put(`${apiBaseUrl}/users/${userId}/password`, { newPassword: password })
        .then(() => ([]))
        .catch(error => {
          if(error.response.status === 400) {
            return error.response.data.reasons
          }
          return ["Something wrong"]
        }),
    updateUserEmail: (userId: string, email?: string): Promise<string[]> =>
      axiosInstance
        .put(`${apiBaseUrl}/users/${userId}/email`, { email })
        .then(() => [])
        .catch(error => {
          if(error.response.status === 400) {
            return error.response.data.reasons
          }
          return ["Something wrong"]
        }),
    updateUserLdapQuery: (userId: string, ldapQuery: string) =>
      axiosInstance
        .put(`${apiBaseUrl}/users/${userId}/ldapQuery`, { ldapQuery }),
    getUserGroups: (userId: string) =>
      axiosInstance
        .get(`${apiBaseUrl}/users/${userId}/groups`)
        .then(response => response.data as GroupWithId[]),
    assignUserToGroups: (userId: string, groupIds: string[]) =>
      axiosInstance
        .put(`${apiBaseUrl}/users/${userId}/groups`, groupIds)
        .then(() => { /* Unimplemented */})
  }
}