import {AxiosInstance} from "axios"
import {Client, ClientData} from "../models/client"

export const ClientService = (axiosInstance: AxiosInstance) => ({
  getClients: async (): Promise<Client[]> => {
    return axiosInstance
      .get("/organization-admin/api/v1/clients")
      .then(result => result.data as Client[])
  },
  getClientById: async (clientId: string): Promise<Client> => {
    return axiosInstance
      .get(`/organization-admin/api/v1/clients/${clientId}`)
      .then(result => result.data as Client)
  },
  createClient: (clientData: ClientData) : Promise<string> =>
    axiosInstance
      .post("/organization-admin/api/v1/clients", clientData)
      .then(result => result.data.id)
      .catch(error => {
        throw new Error(error)
      }),
  updateClient: async (clientId: string,request: any) : Promise<string> => {
    const requests : any  = {
      name : request.name,
      description : request.description,
      info: {
        type: request.type,
        redirectUrls: request.redirectUrls.split(";")
      }
    }
    const result = await axiosInstance.put(`/organization-admin/api/v1/clients/${clientId}`,
      requests)
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  },
  deleteClient: async (clientId: string) : Promise<string> => {
    const result = await axiosInstance.delete(`/organization-admin/api/v1/clients/${clientId}`)
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  }
})
