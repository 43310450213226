import {AxiosInstance} from "axios"
import dayjs from "dayjs"

export type Group = {
  name: string
  description: string
  notes: string,
  createdAt?: dayjs.Dayjs,
  updatedAt?: dayjs.Dayjs
}

export type GroupWithId = Group & { id: string }

export type GroupWithSubgroups = GroupWithId & {
  subGroups: GroupWithSubgroups[]
}

const getSubGroups = (axiosInstance: AxiosInstance) => async (groupId: string): Promise<GroupWithSubgroups[]> =>
  axiosInstance
    .get(`/organization-admin/api/v1/groups/${groupId}/subGroups`)
    .then(async response => {
      const responseData = response.data as GroupWithId[]
      const subGroups = responseData.map(group => ({ name: group.name, description: group.description, id: group.id, notes: group.notes, subGroups: [] }))
      return Promise.all(
        subGroups.map(async subGroup => ({
          ...subGroup,
          subGroups: await getSubGroups(axiosInstance)(subGroup.id)
        }))
      )
    })

const getGroups = (axiosInstance: AxiosInstance) => async (): Promise<GroupWithId[]> =>
  axiosInstance
    .get("/organization-admin/api/v1/groups")
    .then(response => {
      const groups = response.data as GroupWithId[]
      return groups.map((group: GroupWithId) => {
        const {id, name, description, notes } = group
        return ({id, name, description, notes })
      })
    })
    .catch(error => {
      console.log("error", error)
      throw new Error(`GroupServiceError: Unable to get groups ${error.message}`)
    })

const getGroupById = (axiosInstance: AxiosInstance) => async (groupId: string): Promise<GroupWithSubgroups> => {
  const groupInfo: GroupWithId = await axiosInstance
    .get(`/organization-admin/api/v1/groups/${groupId}`)
    .then(async response => {
      const {id, name, description, createdAt, notes, updatedAt} = response.data as GroupWithId
      return {id, name, description, createdAt, notes, updatedAt}
    })
    .catch(error => {
      console.log("error", error)
      throw new Error("GroupServiceError: Unable to get group")
    })
  return {
    ...groupInfo,
    subGroups: await getSubGroups(axiosInstance)(groupId)
  }
}
export const GroupService = (axiosInstance: AxiosInstance) => {
  return ({
    getGroups: getGroups(axiosInstance),
    getGroupById: getGroupById(axiosInstance),
    getGroupTree: async () =>
      axiosInstance.get("/organization-admin/api/v1/groups?join=true")
        .then(response => response.data),
    createGroup: async (request: any): Promise<string> => {
      const requests: any = {
        parentId: request.parentId || "",
        name: request.name,
        description: request.description,
        notes: request.notes,
        createDate: dayjs()
      }
      const result = await axiosInstance
        .post("/organization-admin/api/v1/groups", requests)
      const {status = 404} = result
      if (status === 201) {
        return "success"
      }
      return "error"
    },
    updateGroup: async (groupId: string, request: any): Promise<string> => {
      const requests: any = {
        name: request.name,
        parentId: request.parentId || "",
        description: request.description,
        notes: request.notes,
        updateDate: dayjs()
      }
      const result = await axiosInstance
        .put(`/organization-admin/api/v1/groups/${groupId}`, requests)
      const {status = 404} = result
      if (status === 200) {
        return "success"
      }
      return "error"
    },
    deleteGroup: async (groupId: string): Promise<void> => {
      await axiosInstance
        .delete(`/organization-admin/api/v1/groups/${groupId}`)
    },
    setClientRoles: async (groupId: string, clientId: string, clientRoles: string[]) =>
      axiosInstance
        .put(`/organization-admin/api/v1/groups/${groupId}/clients/${clientId}/roles`, clientRoles),
    getClientRoles: async (groupId: string, clientId: string): Promise<string[]> => {
      try {
        return await axiosInstance
          .get(`/organization-admin/api/v1/groups/${groupId}/clients/${clientId}/roles`)
          .then(result => result.data)
      } catch (e) {
        return []
      }
    }
  })
}
