import React, {Key, useEffect, useState} from "react"
import {Tree} from "antd"
import type {DataNode} from "antd/lib/tree"
import {GroupWithSubgroups} from "../../../../services/group-service"
import {groupWithSubGroupsToGroupTree} from "./group-tree-mapper"

type Props = {
  group: GroupWithSubgroups
  onSelect: (groupId?: string) => void
}

export const GroupTree: React.FC<Props> = ({group, onSelect}): React.ReactElement => {
  const [treeData, setTreeData] = useState<DataNode[]>([])
  const [expandedKey, setExpandedKey] = useState<Key[]>([])

  useEffect(() => {
    setTreeData([groupWithSubGroupsToGroupTree(group)])
  }, [group])

  return (
    <div>
      <Tree
        showLine={true}
        showIcon={false}
        onSelect={(key: Key[], info: any) => onSelect(key[0] as string)}
        treeData={treeData}
        expandedKeys={expandedKey}
        onExpand={setExpandedKey}
        selectable
      />
    </div>
  )
}