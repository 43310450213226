import React, {useEffect, useState} from "react"
import {Button, Card, Col, Modal, Row, Typography, notification} from "antd"
import { useParams , useLocation} from "react-router-dom"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {ClientRolesTable} from "./components/ClientRolesTable"
import {AddClientRole} from "./components/AddClientRole"
import {ClientRoleService} from "../../../services/client-role-service"
import {WarningOutlined} from "@ant-design/icons"
import {ClientService} from "../../../services/client-service"

const { Title } = Typography

type Client = {
  id: string
  name: string
  description: string
  info: {
    type: string
    redirectUrls: string[]
  }
}
type ClientDetail = {
  detail? : Client
}
interface LocationParams {
  pathname: string;
  state: ClientDetail;
  search: string;
  hash: string;
  key: string;
}
type ClientRole = {
  id: string
  name: string
  description: string
}

const defaultClientDetail: Client = {
  id: "",
  name: "",
  description: "",
  info: {
    type: "",
    redirectUrls: []
  }
}
export const ClientDetail: React.FC = (): React.ReactElement => {
  const { clientId } = useParams()
  const location = useLocation () as LocationParams
  const { id24Axios } = useId24()
  const [clientRoles, setClientRoles] = useState<ClientRole[]>([])
  const [editingClientRole, setEditingClientRole] = useState<ClientRole|null>()
  const [roleId, setRoleId] = useState<string>("")
  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  const [visibleModalConfirmDelete, setVisibleModalConfirmDelete] = useState<boolean>(false)
  const [modeEdit, setModeEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [client, setClient] = useState<Client>((location.state && location.state.detail) || defaultClientDetail)

  const onEditClientRoleId = (roleId: string, clientRole: ClientRole) => {
    setModeEdit(true)
    setRoleId(roleId)
    setEditingClientRole(clientRole)
    handleVisibleModal(true)
  }
  const onDelete = (roleId: string) => {
    setRoleId(roleId)
    setVisibleModalConfirmDelete(true)
  }
  const onAddClientRole = () => {
    setModeEdit(false)
    setEditingClientRole(null)
    handleVisibleModal(true)
  }

  const clientRoleService = ClientRoleService(id24Axios())
  const clientService = ClientService(id24Axios())

  useEffect(() => {
    if(location.state === null) {
      getClientDetail()
    }
  }, [location.state])
  useEffect(() => {
    getClientRolesService()
  }, [])
  const handleVisibleModal= (visible: boolean) => setVisibleModal(visible)
  const getClientRolesService = () => {
    setLoading(true)
    clientRoleService
      .getClientRoles(clientId as string)
      .then(clientRoles => {
        setClientRoles(clientRoles)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        notification.error({
          message: "error"
        })
      })
  }
  const getClientDetail = () => {
    setLoading(true)
    clientService
      .getClientById(clientId as string)
      .then(clientResponse => {
        setClient(clientResponse)
      }).catch(() => {
        notification.error({
          message: "get client defail failed"
        })
      })
  }
  const handleOnConfirm = async (clientRoles: any) => {
    const response = modeEdit ? await clientRoleService.updateClientRoleById(clientId as string, roleId, clientRoles) : await clientRoleService.setClientRoles(clientId as string, clientRoles)
    if(response==="success"){
      notification.success({
        message: "success"
      })
      getClientRolesService()
    }else{
      notification.error({
        message: "error"
      })
    }
    setVisibleModal(false)
  }
  const handleDeleteRoleId = async () => {
    const response = await clientRoleService.deleteClientRoleById(clientId as string,roleId)
    if(response==="success"){
      notification.success({
        message: "success"
      })
      setVisibleModalConfirmDelete(false)
      getClientRolesService()
    }else{
      notification.success({
        message: "success"
      })
    }
  }
  return (
    <>
      <Row>
        <Col>
          <Title>Client Detail</Title>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Card>
            <Row>
              <span>Id: {client.id}</span>
            </Row>
            <Row>
              <span>Name: {client.name}</span>
            </Row>
            <Row>
              <span>Description: {client.description}</span>
            </Row>
            <Row>
              <span>Type: {client.info.type}</span>
            </Row>
            <Row>
              <span>RedirectUrls: {client.info.redirectUrls.join(";")}</span>
            </Row>
            <br />
            <Row justify="end">
              <Col>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={onAddClientRole}
                  type="primary"
                >
                  +Add Client Roles
                </Button>
              </Col>
            </Row>
            <br/>
            <Modal
              title={modeEdit ? "Edit Client Role" : "Add Client Role"}
              visible={visibleModal}
              onCancel={() => handleVisibleModal(false)}
              footer={null}
            >
              <AddClientRole
                submitLabel={modeEdit ? "Update" : "Create"}
                initialValue={editingClientRole}
                onConfirm={handleOnConfirm}
              />
            </Modal>
            <Modal
              title={null}
              visible={visibleModalConfirmDelete}
              onCancel={() => setVisibleModalConfirmDelete(false)}
              footer={null}
            >
              <div className="modal-content">
                <div className="modal-content-body">
                  <WarningOutlined className="icon-warning"/>
                  <p>Do you want to Delete</p>
                </div>
                <div>
                  <Button onClick={() => setVisibleModalConfirmDelete(false)}>Cancel</Button>
                  &nbsp;&nbsp;
                  <Button type="primary" onClick={handleDeleteRoleId}>Ok</Button>
                </div>
              </div>
            </Modal>
            <ClientRolesTable
              clientRoles={clientRoles}
              loading={loading}
              onDelete={onDelete}
              onEdit={onEditClientRoleId}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}
