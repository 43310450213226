import React from "react"
import {Modal} from "antd"
import {ClientFormData, EditClientForm} from "../screens/components/EditClientForm"
import {LoginOption} from "../../../models/client"

type Props = {
  allowOptions: LoginOption[]
  visible: boolean
  onCancel: () => void
  onCreate: (client: ClientFormData) => void
}

export const AddClientDialog: React.FC<Props> = ({
  allowOptions,
  visible,
  onCancel,
  onCreate,
}): React.ReactElement =>
  <Modal
    title="Add Client"
    visible={visible}
    centered
    onCancel={onCancel}
    footer={null}
  >
    <EditClientForm
      allowedOptions={allowOptions}
      submitLabel="Create"
      initialValue={null}
      onConfirm={onCreate}
    />
  </Modal>

