import React from "react"
import {Tooltip, Table} from "antd"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

export type Group = {
  id: string,
  name: string
  description: string
  notes: string
  createdAt?: dayjs.Dayjs,
  updatedAt?: dayjs.Dayjs
}

type Props = {
  groupList: Group[]
  onEdit: (groupId: string, group: Group) => void
  onDelete: (groupId: string) => void
  onSelect: (group: Group) => void
  loading: boolean
}

export const GroupTable: React.FC<Props> = ({
  groupList,
  onDelete,
  onEdit,
  onSelect,
  loading
}): React.ReactElement => {
  const columns = [
    {
      title: "Id",
      key: "id",
      width: "100px",
      render: (group: Group) => {
        return <a onClick={() => onSelect(group)}>{group.id?.substring(0,5)}...</a>
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "200px",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: "200px",
    },
    {
      title: "Action",
      key: "action",
      width: "8%",
      fixed: "right" as any,
      render: (group: Group) => (
        <div>
          <Tooltip placement="top" title="Edit">
            <EditOutlined style={{ color : "#1890ff" , fontSize: "20px"}} onClick={(e) => {
              e.stopPropagation()
              onEdit(group.id, group)
            }}/>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="top" title="Delete">
            <DeleteOutlined style={{ color : "red" , fontSize: "20px"}}  onClick={(e) => {
              e.stopPropagation()
              onDelete(group.id)
            }}/>
          </Tooltip>
        </div>

      )
    }
  ]

  return <>
    <Table
      rowKey="id"
      dataSource={groupList}
      columns={columns}
      loading={loading}
      scroll={{ x: 1400 }}
      footer={() => `Total ${groupList.length} Row`}
    />
  </>
}
