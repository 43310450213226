import React from "react"
import {Table, Tag} from "antd"
import type {ColumnsType} from "antd/es/table"
import {AuditLog} from "../../../../models/audit-logs/audit-log"
import dayjs from "dayjs"
import {LoginFailInfo, LoginFailReason} from "../../../../models/audit-logs/login-fail-audit-log"
import {AuditLogEventType} from "../../../../models/audit-logs/audit-log-event-type"
import {INavigator} from "./INavigator"
import {AuditLogMessage} from "./AuditLogMessage"
import {AccountActivityInfo} from "../../../../models/audit-logs/account-activity-audit-log"

const renderAuditLogActivity = (activity: AuditLogEventType, auditLog: AuditLog) => {
  if(activity === AuditLogEventType.LoginFailed) {
    const { reason } = auditLog.info as LoginFailInfo
    return <Tag color={"error"}>
      Login failed ({reason})
    </Tag>
  } else if (activity === AuditLogEventType.AccountActivity) {
    const { activity } = auditLog.info as AccountActivityInfo
    return <Tag color={"success"}>
      Account activity ({activity})
    </Tag>
  } else {
    return <></>
  }
}

const columns = (navigator: INavigator): ColumnsType<AuditLog>  => ([
  {
    title: "Date & Time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: createdAt => <a>{createdAt.format()}</a>,
  },
  {
    title: "Activity",
    dataIndex: "activity",
    key: "activity",
    render: renderAuditLogActivity
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    render: (message: string, auditLog: AuditLog) =>
      <AuditLogMessage navigator={navigator} message={message} auditLog={auditLog}/>,
  }
])

const data: AuditLog[] = [
  {
    id: "audit-log-id",
    activity: AuditLogEventType.LoginFailed,
    info: {
      reason: LoginFailReason.InvalidRedirectUrl,
      redirectUrl: "https://google.com",
      clientId: "client-id",
      organizationId: "organization-id",
    },
    message: "Login failed",
    createdAt: dayjs("2021-01-01 00:00:00"),
  },
  {
    id: "audit-log-id-2",
    activity: AuditLogEventType.LoginFailed,
    info: {
      reason: LoginFailReason.UsernameNotFound,
      username: "John-wick",
      clientId: "client-id",
      organizationId: "organization-id",
    },
    message: "Username not found",
    createdAt: dayjs("2021-01-01 00:00:00"),
  },
  {
    id: "audit-log-id-3",
    activity: AuditLogEventType.LoginFailed,
    info: {
      reason: LoginFailReason.InvalidPassword,
      userId: "user-id",
      clientId: "client-id",
      organizationId: "organization-id",
    },
    message: "Invalid password",
    createdAt: dayjs("2021-01-01 00:00:00"),
  },
  {
    id: "audit-log-id-4",
    activity: AuditLogEventType.LoginFailed,
    info: {
      reason: LoginFailReason.UserInactive,
      userId: "user-id",
      clientId: "client-id",
      organizationId: "organization-id",
    },
    message: "User inactive",
    createdAt: dayjs("2021-01-01 00:00:00"),
  },
  {
    id: "audit-log-id-5",
    activity: AuditLogEventType.LoginFailed,
    info: {
      reason: LoginFailReason.LDAPAuthenticationFailed,
      username: "John-wick",
      clientId: "client-id",
      organizationId: "organization-id",
    },
    message: "LDAP authentication failed",
    createdAt: dayjs("2021-01-01 00:00:00"),
  }
]

type Props = {
  navigator: INavigator
  auditLogs: AuditLog[]
  totalAuditLogs: number,
  loadPage: (page: number, pageSize: number) => void
}

export const AuditLogTable: React.FC<Props> = ({
  navigator,
  auditLogs,
  totalAuditLogs,
  loadPage}): React.ReactElement => {
  return <Table
    columns={columns(navigator)}
    dataSource={auditLogs}
    pagination={{
      defaultPageSize: 5,
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "50", "100"],
      total: totalAuditLogs,
      onChange: loadPage,
      onShowSizeChange: loadPage,
    }}
  />
}