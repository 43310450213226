import React from "react"
import {Button, Form, Input, Statistic} from "antd"

type PasswordData = {
  password: string
  rePassword: string
  currentPassword?: string
}
type Props = {
  onSetPassword: (newPassword: string, currentPassword?: string) => void
  loginPageTitle: {
    title: string
  },
  expireTimeInSeconds?: number
  onExpire?: () => void
  askOldPassword?: boolean
}
export const SetPasswordForm: React.FC<Props> = ({
  onSetPassword,
  loginPageTitle,
  expireTimeInSeconds,
  onExpire,
  askOldPassword,
}): React.ReactElement => {
  const [form ] = Form.useForm<PasswordData>()
  const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 }}
  const buttonLayout = { wrapperCol: { offset: 8, span: 16 }}

  return <>
    <div className="form-layout">
      <div className="form">
        <div className="detail">
          <p className="header" style={{textAlign: "center"}}>{ loginPageTitle.title }</p>
          <p className="header" style={{textAlign: "center"}}>ระบบบันทึกข้อมูลสำหรับบริษัทติดตามหนี้</p>
        </div>

        <div style={{fontWeight: 500, fontSize: "18px"}} className="mb-4">
          <Form form={form} onFinish={({password, currentPassword}) => onSetPassword(password, currentPassword)} {...layout}>
            {
              askOldPassword
                ? <Form.Item
                  name="currentPassword"
                  label="Current Password"
                  rules={[
                    { required: askOldPassword, message: "Please input your current password"}
                  ]}>
                  <Input.Password/>
                </Form.Item>
                : <></>
            }
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password"}
              ]}>
              <Input.Password/>
            </Form.Item>
            <Form.Item
              name="rePassword"
              label="Re-Password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password"
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(!value || getFieldValue("password") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error("Password not matched"))
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...buttonLayout}>
              <Button type="primary" htmlType="submit">Set Password</Button>
              { (expireTimeInSeconds && onExpire) ? <Statistic.Countdown format={"mm:ss"} value={Date.now() + 1000 * expireTimeInSeconds} onFinish={onExpire}/> : <></> }
            </Form.Item>
          </Form>

        </div>
        <br/>
      </div>
    </div>
  </>
}