import React from "react"
import {Button, Form, Input} from "antd"
import {emailValidationRule} from "../validators/email-validation-rule"

type Props = {
  initialLdapQuery?: string
  onSubmit: (ldapQuery: string) => void
}

type FormData = {
  ldapQuery: string
}

export const UpdateOrganizationUnitsForm: React.FC<Props> = ({
  initialLdapQuery,
  onSubmit,
}) => {
  const [form] = Form.useForm<FormData>()
  return <Form form={form} onFinish={({ldapQuery}) => onSubmit(ldapQuery)}>
    <Form.Item
      name="ldapQuery"
      label="LDAP Query">
      <Input placeholder={ initialLdapQuery || "CN=John Smith,OU=User,DC=example"}/>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Update
      </Button>
    </Form.Item>
  </Form>
}