import React from "react"
import {Modal} from "antd"
import {EditLDAPUser, LDAPUserData} from "../EditLDAPUser"

type Props = {
  visible: boolean
  validateUsername: (username: string) => Promise<string | undefined>
  validateEmail: (email: string) => Promise<string | undefined>
  onAddUser: (userData: LDAPUserData) => void
  onCancel: () => void
  working: boolean
}

export const AddLdapUserDialog: React.FC<Props> = ({
  visible,
  validateUsername,
  validateEmail,
  onAddUser,
  onCancel,
  working
}) =>
  <Modal
    title="Add LDAP user"
    visible={visible}
    onCancel={onCancel}
    footer={null}
  >
    <EditLDAPUser
      onSubmit={onAddUser}
      loading={working}
      onValidateUsername={validateUsername}
      onValidateEmail={validateEmail}/>
  </Modal>
