import React from "react"
import {Route, Routes, Navigate} from "react-router-dom"
import {ClientManagement} from "../screens/ClientManagement"
import {OrganizationManagement} from "../screens/OrganizationManagement"
import {GroupManagement} from "../screens/GroupManagement"
import {GroupDetail} from "../screens/GroupDetail"
import {UserManagement} from "../screens/UserManagement"
import {ClientDetail} from "../screens/ClientDetail"
import {UserDetail} from "../screens/UserDetail"
import {AuditLogs} from "../screens/AuditLogs"

export const Test: React.FC = (): React.ReactElement => {
  return <>Test</>
}

type Props = {
  defaultRoute: string
}

export const MainRoute: React.FC<Props> = ({defaultRoute}): React.ReactElement => {
  return <div>
    <Routes>
      <Route path="/test" element={<Test/>}/>
      <Route path="/clients" element={<ClientManagement/>}/>
      <Route path="/clients/:clientId/detail" element={<ClientDetail/>}/>
      <Route path="/groups" element={<GroupManagement/>}/>
      <Route path="/groups/:groupId/subGroups" element={<GroupDetail/>}/>
      <Route path="/users" element={<UserManagement/>}/>
      <Route path="/users/:userId/detail" element={<UserDetail/>}/>
      <Route path="/audit-logs" element={<AuditLogs/>}/>
      <Route path="/organizations" element={<OrganizationManagement/>}/>
      <Route path="*" element={<Navigate to={defaultRoute}/>}/>
    </Routes>
  </div>
}