import React from "react"
import {Modal} from "antd"
import {EditLocalUser, LocalUserData} from "../EditLocalUser"

type Props = {
  visible: boolean
  requirePhoneNumber: boolean
  validateUsername: (username: string) => Promise<string | undefined>
  validateEmail: (email: string) => Promise<string | undefined>
  onAddUser: (userData: LocalUserData) => void
  onCancel: () => void
  working: boolean
}

export const AddLocalUserDialog: React.FC<Props> = ({
  visible,
  requirePhoneNumber,
  validateUsername,
  validateEmail,
  onAddUser,
  onCancel,
  working
}) =>
  <Modal
    title="Add Local user"
    visible={visible}
    onCancel={onCancel}
    footer={null}
  >
    <EditLocalUser
      onSubmit={onAddUser}
      loading={working}
      onValidateEmail={validateEmail}
      onValidateUsername={validateUsername}
      requirePhoneNumber={requirePhoneNumber}
    />
  </Modal>
