import React, {useEffect} from "react"
import {Button, Form, Input} from "antd"
import {emailValidationRule} from "../validators/email-validation-rule"

export type LocalUserData = {
  username: string
  email?: string
  phoneNumber?: string
}

type EProps = {
  onSubmit: (userData: LocalUserData) => void
  onValidateUsername: (username: string) => Promise<string | undefined>
  onValidateEmail: (email: string) => Promise<string | undefined>
  requirePhoneNumber: boolean
  loading: boolean
}

type UserFormData = {
  username: string
  email: string
  phoneNumber?: string
}

export const EditLocalUser: React.FC<EProps> = ({
  onSubmit,
  onValidateUsername,
  onValidateEmail,
  requirePhoneNumber,
  loading
}): React.ReactElement => {
  const [form] = Form.useForm<UserFormData>()

  useEffect(() => {
    form.resetFields()
  }, [])

  return <Form form={form} onFinish={({username, email, phoneNumber}) => onSubmit({
    username,
    email: (email !== undefined && email.trim().length > 0) ? email.trim() : undefined,
    phoneNumber
  })}>
    <Form.Item
      name="email"
      label="Email"
      rules={emailValidationRule(onValidateEmail)}
    >
      <Input/>
    </Form.Item>
    <Form.Item
      name="username"
      label="Username"
      rules={[
        {required: true, message: "Username is required."},
        {validator: async (_, value) => {
          const validationFailReason = await onValidateUsername(value)
          if(validationFailReason) {
            throw new Error(validationFailReason)
          }
        }}
      ]}
    >
      <Input/>
    </Form.Item>
    {
      requirePhoneNumber && <Form.Item
        name="phoneNumber"
        label="Phone Number"
        rules={[ ...(requirePhoneNumber ? [{ required: true, message: "Phone number is required." }] : []) ]}
      >
        <Input/>
      </Form.Item>
    }
    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Add
      </Button>
    </Form.Item>
  </Form>
}