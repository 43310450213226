import React, {useEffect, useState} from "react"

import "./Login.css"
import {AuthService, PasswordExpireMessage} from "../../services/auth-service"
import {Credential, LoginForm} from "./components/LoginForm"
import {SetPasswordForm} from "./components/SetPasswordForm"
import {MyAccountService} from "../../services/my-account-service"
import {WelcomeAccountPage} from "./components/WelcomeAccountPage"
import {useNavigate} from "react-router-dom"
import {message} from "antd"
import axios from "axios"
import {LoginOption} from "../../models/login-option"
import {LoggedInCTA} from "./components/LoggedInCTA"

type Props = {
  loginOptions: LoginOption[]
  loginPageTitle: {
    title: string
  }
}

export const Login: React.FC<Props> = ({loginOptions, loginPageTitle}): React.ReactElement => {
  const [updatePasswordToken, setUpdatePasswordToken] = useState<string | null>(null)
  const [expireTimeInSeconds, setExpireTimeInSeconds] = useState<number>(0)
  const [loginOption, setLoginOption] = useState<LoginOption>(loginOptions[0])
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false)
  const [isPasswordExpired, setIsPasswordExpired] = useState<boolean>(false)
  const [clientName, setClientName] = useState<string | undefined>(undefined)
  const navigate = useNavigate()

  const authService = AuthService(axios)

  const urlSearchParams = new URLSearchParams(window.location.search)
  const {clientId, redirectUrl, codeVerifier, autoLogin} = Object.fromEntries(
    urlSearchParams.entries()
  )
  const decodedRedirectUrl = decodeURIComponent(redirectUrl)

  const tryAuthorize = () =>
    authService
      .tryAuthorize({
        clientId, redirectUrl, codeVerifier
      })
      .then(({code}) => {
        if(code) {
          window.location.href = decodedRedirectUrl.includes("?")
            ? `${decodedRedirectUrl}&code=${code}`
            : `${decodedRedirectUrl}?code=${code}`
        }
      })
      .catch(() => console.log("user is not authorized yet"))

  const logout = () =>
    authService
      .deAuthorize()
      .then(() => setUserLoggedIn(false))

  useEffect(() => {
    console.log("autoLogin", autoLogin)
    if(autoLogin === "true") {
      tryAuthorize()
    } else {
      authService
        .isAuthorized()
        .then(() => setUserLoggedIn(true))
        .catch(() => setUserLoggedIn(false))
    }
  }, [])

  useEffect(() => {
    if(clientId) {
      authService
        .getClientName(clientId)
        .then(clientNameResult => setClientName(clientNameResult))
    }

  }, [clientId])

  const login = async (credential: Credential, rememberMe: boolean) => {
    const {username, password} = credential
    try {
      const authorize = loginOption === LoginOption.Local
        ? authService.authorize
        : loginOption === LoginOption.Ldap ? authService.authorizeLdap : authService.authorizeLdapWithQuery
      const { code, updatePasswordToken, tokenExpireInSeconds, message } = await authorize({
        clientId,
        redirectUrl: decodedRedirectUrl,
        codeVerifier: decodeURIComponent(codeVerifier),
        credential: {
          username,
          password,
        },
        rememberMe
      })

      if(code) {
        window.location.href = decodedRedirectUrl.includes("?")
          ? `${decodedRedirectUrl}&code=${code}`
          : `${decodedRedirectUrl}?code=${code}`
      } else if (updatePasswordToken) {
        setUpdatePasswordToken(updatePasswordToken)
        setExpireTimeInSeconds(tokenExpireInSeconds)
        setIsPasswordExpired(message === PasswordExpireMessage)
      }
    } catch (e: any) {
      message.error(e.message)
    }
  }

  const myAccountService = MyAccountService(axios)
  const onSetPassword = async (newPassword: string) => {
    if(updatePasswordToken) {
      myAccountService
        .setPassword(newPassword, updatePasswordToken)
        .then((errors) => {
          if(errors.length > 0) {
            message.error({
              content: errors.map(error => (<><>{ error }</><br/></>))
            })
          } else {
            setUpdatePasswordToken(null)
          }
        })
    }
  }

  const onSetPasswordWithOldPassword = (newPassword: string, oldPassword?: string) => {
    myAccountService
      .setPasswordWithOldPassword(newPassword, oldPassword as string, updatePasswordToken as string)
      .then(() => navigate(-1))
      .catch((error) => message.error(error.message))
  }

  const onForgetPassword = () => {
    navigate("/forget-password")
  }

  return <WelcomeAccountPage>
    {updatePasswordToken
      ? isPasswordExpired
        ? <SetPasswordForm
          onSetPassword={onSetPasswordWithOldPassword}
          loginPageTitle={loginPageTitle}
          expireTimeInSeconds={expireTimeInSeconds}
          onExpire={() => setUpdatePasswordToken(null)}
          askOldPassword
        />
        : <SetPasswordForm
          onSetPassword={onSetPassword}
          loginPageTitle={loginPageTitle}
          expireTimeInSeconds={expireTimeInSeconds}
          onExpire={() => setUpdatePasswordToken(null)}
        />
      : userLoggedIn
        ? <LoggedInCTA clientName={clientName} onContinue={tryAuthorize} onDeAuthorize={logout} loginPageTitle={loginPageTitle}/>
        : <LoginForm
          clientName={clientName}
          onLogin={login}
          loginPageTitle={loginPageTitle}
          onForgetPassword={onForgetPassword}
          loginOption={loginOption}
          setLoginOption={setLoginOption}
          loginOptions={loginOptions}
        />
    }

  </WelcomeAccountPage>
}

