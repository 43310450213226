import React, {useEffect, useState} from "react"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {User, UserQuery, UserService, UserType} from "../../../services/user-service"
import {UserTable} from "./components/UserTable"
import {useNavigate} from "react-router-dom"
import {Button, Card, Col, Input, message, Row, Select, Space, Typography} from "antd"
import {AddLocalUserDialog} from "./components/dialogs/AddLocalUserDialog"
import {LDAPUserData as UILDAPUserData} from "./components/EditLDAPUser"
import {LocalUserData as UILocalUserData} from "./components/EditLocalUser"
import {RegistrationService} from "../../../services/registration-service"
import {AddLdapUserDialog} from "./components/dialogs/AddLdapUserDialog"
import {loadAppConfig} from "../../../config/app-config"
import {MyOrganization, MyOrganizationService} from "../../../services/my-organization-service"
import {LoginSettingType} from "../../../services/organization-service"

enum SearchUserOption {
  email = "email",
  username = "username"
}

export const UserManagement: React.FC = (): React.ReactElement => {
  const { id24Axios } = useId24()
  const navigate = useNavigate()
  const loadedAppConfig = loadAppConfig()
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)
  const [showAddLocalUserDialog, setShowAddLocalUserDialog] = useState(false)
  const [showAddLdapUserDialog, setShowAddLdapUserDialog] = useState(false)
  const [addingUser, setAddingUser] = useState(false)
  const [searchOption, setSearchOption] = useState<SearchUserOption>(SearchUserOption.username)
  const [myOrganization, setMyOrganization] = useState<MyOrganization|null>(null)

  const userService = UserService(id24Axios())
  const registrationService = RegistrationService(id24Axios())
  const myOrganizationService = MyOrganizationService(id24Axios())
  useEffect(() => {
    loadUser({})
    myOrganizationService
      .getMyOrganization()
      .then(async (organization) => {
        setMyOrganization(organization)
      })
      .catch((error) => {
        message.error("Failed to load organization info")
      })
  }, [])

  const loadUser = (userQuery: UserQuery) => {
    setLoading(true)
    userService
      .getUsers(userQuery)
      .then(usersResponse => {
        setUsers(usersResponse)
        setLoading(false)
      })
  }

  const createLocalUser = (userData: UILocalUserData) => {
    setAddingUser(true)
    userService
      .createUser({
        ...userData,
        type: UserType.Local,
        loginUrl: loadedAppConfig.authServer,
      })
      .then(() => {
        setAddingUser(false)
        setShowAddLocalUserDialog(false)
        loadUser({})
      })
      .catch(async () => {
        setAddingUser(false)
        await message.error("Add user failed")
      })
  }

  const createLdapUser = (ldapUser: UILDAPUserData) => {
    setAddingUser(true)
    userService
      .createUser({
        ...ldapUser,
        type: UserType.Ldap,
        loginUrl: loadedAppConfig.authServer,
      })
      .then(() => {
        setAddingUser(false)
        setShowAddLdapUserDialog(false)
        loadUser({})
      })
      .catch(async () => {
        setAddingUser(false)
        await message.error("Add user failed")
      })
  }

  const validateUsername = (username: string, type: UserType): Promise<string | undefined> =>
    registrationService
      .validateUserName(username, type)

  const validateUserEmail = async (email: string, type: UserType): Promise<string | undefined> =>
    registrationService
      .validateUserEmail(email, type)

  const onSearch = (value: string) => {
    if(value) {
      loadUser({[searchOption]: value})
    } else {
      loadUser({})
    }
  }

  return <>
    <Row>
      <Col>
        <Typography.Title>User Management</Typography.Title>
      </Col>
    </Row>
    <Row>
      <Card>
        <Row justify="end">
          <Col>
            <Space>
              <Select defaultValue={SearchUserOption.username} style={{ width: 120 }} onChange={setSearchOption}>
                <Select.Option value={SearchUserOption.username}>Username</Select.Option>
                <Select.Option value={SearchUserOption.email}>Email</Select.Option>
              </Select>
              <Input.Search allowClear onSearch={onSearch}/>
            </Space>
          </Col>
          <Col>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => setShowAddLocalUserDialog(true)}
              type="primary"
              loading={myOrganization === null}
            >
              +Add Local User
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => setShowAddLdapUserDialog(true)}
              type="primary"
              loading={myOrganization === null}
            >
              +Add LDAP User
            </Button>
          </Col>
        </Row>

        <br/>

        <UserTable
          users={users}
          onSelect={(user) => navigate(`/main/users/${user.id}/detail`)}
          loading={loading}
        />

      </Card>
      <AddLocalUserDialog
        visible={showAddLocalUserDialog}
        requirePhoneNumber={
          myOrganization !== null
            ? myOrganization.loginSettings.find(setting => setting.type === LoginSettingType.DeviceVerification) !== undefined
            : false
        }
        validateUsername={(username) => validateUsername(username, UserType.Local)}
        validateEmail={(email) => validateUserEmail(email, UserType.Local)}
        onAddUser={user => createLocalUser(user)}
        onCancel={() => setShowAddLocalUserDialog(false) }
        working={addingUser}
      />
      <AddLdapUserDialog
        visible={showAddLdapUserDialog}
        validateUsername={(username) => validateUsername(username, UserType.Ldap)}
        validateEmail={(email) => validateUserEmail(email, UserType.Ldap)}
        onAddUser={user => createLdapUser(user)}
        onCancel={() => setShowAddLdapUserDialog(false) }
        working={addingUser}
      />
    </Row>
  </>
}

