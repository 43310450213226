import {AuditLogCommon} from "./audit-log-common"
import {AuditLogEventType} from "./audit-log-event-type"

export type AccountActivityAuditLog = AuditLogCommon & AccountActivityAuditLogData

export type AccountActivityAuditLogData = {
  activity: AuditLogEventType.AccountActivity
  info: AccountActivityInfo
  message: string
}

export type AccountActivityInfo =
  AccountActivityUserCreated |
  AccountActivityUserActive

export type CommonAccountActivityInfo = {
  activity: AccountActivity
  userId: string
  adminUserId?: string
  organizationId: string
}

export type AccountActivityUserCreated = CommonAccountActivityInfo & {
  activity: AccountActivity.UserCreated
}

export type AccountActivityUserActive = CommonAccountActivityInfo & {
  activity: AccountActivity.SetUserActive
  active: boolean
}


export enum AccountActivity {
  UserCreated = "user-created",
  SetUserActive = "set-user-active",
}