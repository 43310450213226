export enum ClientType {
  Public = "public",
  Confidential = "confidential"
}

export enum LoginOption {
  Password = "password",
  DeviceId = "device-id",
  PasswordWithDevice = "password-with-device"
}

export type ClientData = {
  name: string
  description: string
  info: {
    type: ClientType,
    redirectUrls: string[]
  }
  loginOption: LoginOption
}

export type Client = ClientData & {
  id: string
}