import React from "react"
import {Button, Card, Descriptions, Select} from "antd"
import {Client} from "../../../../models/client"
import {ClientRole} from "../../../../services/client-role-service"

type Props = {
  groupInfo: {
    name: string
    description: string
    notes: string,
  }
  clients: Client[]
  selectedClient: string | null
  onClientChange: (clientId: string | null) => void
  clientRoles: ClientRole[]
  currentActiveClientRoles: string[]
  selectedClientRoles: string[]
  onSelectClientRoles: (clientRoles: string[]) => void
  onSave: (clientId: string, clientRoles: string[]) => void
}

export const isArrayElementsUnchanged = (
  leftHandSide: any[],
  rightHandSide: any[]
) => {
  const rightHandSizeSet = new Set(rightHandSide)
  return leftHandSide.length === rightHandSide.length && leftHandSide.every(element => rightHandSizeSet.has(element))
}

export const GroupInfo: React.FC<Props> = ({
  groupInfo,
  clients,
  selectedClient,
  onClientChange,
  clientRoles,
  currentActiveClientRoles,
  selectedClientRoles,
  onSelectClientRoles,
  onSave
}): React.ReactElement => {
  console.log("groupInfo", groupInfo)
  const {
    name,
    description,
    notes,
  } = groupInfo
  return <Descriptions bordered>
    <Descriptions.Item label="Name" span={3}>{name}</Descriptions.Item>
    <Descriptions.Item label="Description" span={3}>{description}</Descriptions.Item>
    <Descriptions.Item label="Notes" span={3}>{notes}</Descriptions.Item>
    <Descriptions.Item label="Roles" span={3}>
      <Card title={
        <Select
          onDeselect={() => onClientChange(null)}
          value={selectedClient}
          onChange={onClientChange}
          style={{width: 300}}
        >
          {
            clients.map(client => <Select.Option key={client.id} value={client.id}>{client.name}</Select.Option>)
          }
        </Select>}>
        <Select
          mode="tags"
          placeholder="Please select roles"
          value={selectedClientRoles}
          style={{ width: 300 }}
          onChange={(newValue: string[] | string) => {
            const newValueArray = typeof newValue === "string" ? [newValue] : newValue
            onSelectClientRoles(newValueArray)
          }}
        >
          {
            clientRoles.map(role => <Select.Option key={role.id} value={role.id}>{ role.name }({role.description})</Select.Option>)
          }
        </Select>
        <Button
          onClick={() => selectedClient && onSave(selectedClient, selectedClientRoles)}
        >Save</Button>
      </Card>
    </Descriptions.Item>
  </Descriptions>
}