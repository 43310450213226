import React, {useMemo, useState} from "react"
import {Button, Input, Tree} from "antd"
import {GroupWithSubgroups} from "../../../../services/group-service"
import {groupWithSubGroupsToSelectableGroupTree} from "./group-mapper"
import {Key} from "antd/es/table/interface"
import {BorderOutlined, CheckSquareFilled} from "@ant-design/icons"
import {filterGroups, findParentGroups} from "./group-helper"
type Props = {
  groups: GroupWithSubgroups[]
  onChange: (groupIds: string[]) => void
  initialGroups: string[]
}

/**
 * Requirements
 * - Assign groups
 * - Unassigned groups
 * @param groups
 * @param onChange
 * @param initialGroups
 * @constructor
 */
export const GroupSelectorV2: React.FC<Props> = ({
  groups,
  onChange,
  initialGroups
}): React.ReactElement => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [assignedGroups, setAssignedGroups] = useState<string[]>(initialGroups)
  const [searchValue, setSearchValue] = useState<string>("")
  const [expandedGroupIds, setExpandedGroupIds] = useState<string[]>([])
  const [autoExpandedParent, setAutoExpandedParent] = useState<boolean>(true)

  const onSelect = (keys: Key[], info : { selected: boolean }) => {
    const groupIds = keys as string[]
    setSelectedGroups(groupIds)
  }

  const onAssigned = () => {
    const newAssignedGroups = Array.from(new Set([...assignedGroups, ...selectedGroups]))
    setAssignedGroups(newAssignedGroups)
    setSelectedGroups([])
    onChange(newAssignedGroups)
  }

  const onUnassigned = () => {
    const newAssignedGroups = assignedGroups.filter(group => !selectedGroups.includes(group))
    setAssignedGroups(newAssignedGroups)
    setSelectedGroups([])
    onChange(newAssignedGroups)
  }

  const onSearchChanged = (searchText: string) => {
    const newExpandedGroupIds = findParentGroups(
      (group: GroupWithSubgroups) => group.name.includes(searchText),
      groups
    ).map(group => group.id)
    setExpandedGroupIds(newExpandedGroupIds)
    setSearchValue(searchText)
    setAutoExpandedParent(true)
  }

  const renderedGroups = useMemo(() => {
    return filterGroups((group) => group.name.includes(searchValue), groups)
      .map(group => groupWithSubGroupsToSelectableGroupTree(assignedGroups)(group, (found) => ({
        icon: found ? <CheckSquareFilled/> : <BorderOutlined/>
      })))
  }, [searchValue, groups, assignedGroups])

  const onExpand = (newExpandedKeys: string[]) => {
    setExpandedGroupIds(newExpandedKeys)
    setAutoExpandedParent(false)
  }

  return <>
    <Input.Search style={{ marginBottom: 8 }} placeholder="Search" onChange={evt => onSearchChanged(evt.target.value)}/>
    <Tree.DirectoryTree
      multiple
      onExpand={keys => onExpand(keys as string[])}
      autoExpandParent={autoExpandedParent}
      expandedKeys={expandedGroupIds}
      defaultExpandAll={false}
      selectedKeys={selectedGroups}
      onSelect={onSelect}
      treeData={renderedGroups}
    />
    <Button onClick={onAssigned}>Assign</Button>
    <Button onClick={onUnassigned}>Un-assign</Button>
  </>

}