import React, {useEffect, useState} from "react"
import {Button, Card, Col, message, Modal, notification, Popconfirm, Row, Typography} from "antd"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {useParams} from "react-router-dom"
import {GroupService, GroupWithSubgroups} from "../../../services/group-service"
import {GroupTree} from "./components/GroupTree"
import {GroupInfo} from "./components/GroupInfo"
import {EditGroup, GroupData} from "./components/EditGroup"
import {Client} from "../../../models/client"
import {ClientService} from "../../../services/client-service"
import {ClientRole, ClientRoleService} from "../../../services/client-role-service"

const { Title } = Typography

type GroupDetailData = {
  loading: boolean
  group: GroupWithSubgroups
}

export const findGroup = (group: GroupWithSubgroups, id: string): GroupWithSubgroups | null => {
  if(group.id === id) {
    return group
  }
  const foundSubGroup = group
    .subGroups
    .map(subGroup => findGroup(subGroup, id))
    .filter(mapped => mapped !== null)
  return foundSubGroup[0] || null
}

type GroupInfoProp = {
  name: string
  description: string
  notes: string
}
/**
 * Features
 *  - [x] Add sub groups
 *  - [x] Remove sub groups
 *  - Assign client, and role to group
 * @constructor
 */
export const GroupDetail: React.FC = (): React.ReactElement => {
  const { groupId = ""} = useParams()
  const { id24Axios } = useId24()
  const [groupDetailData, setGroupDetailData] = useState<GroupDetailData>({
    loading: true,
    group: { name: "Loading...", description: "", notes: "", subGroups: [ { name: "subGroup", id: "234", description: "", notes: "", subGroups: [] }], id: "123"}
  })
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>()
  const [groupInfo, setGroupInfo] = useState<GroupInfoProp | null>(null)
  const [addGroupModalVisible, setAddGroupModalVisible] = useState(false)
  const [showEditGroupDialog, setShowEditGroupDialog] = useState(false)
  const [clients, setClients] = useState<Client[]>([])
  const [selectedClient, setSelectedClient] = useState<string|null>(null)
  const [clientRoles, setClientRoles] = useState<ClientRole[]>([])
  const [selectedClientRoles, setSelectedClientRoles] = useState<string[]>([])
  const [currentActiveClientRoles, setCurrentActiveClientRoles] = useState<string[]>([])

  const groupService = GroupService(id24Axios())
  const clientService = ClientService(id24Axios())
  const clientRoleService = ClientRoleService(id24Axios())

  const reloadGroup = () => groupService.getGroupById(groupId)
    .then(groupResponse => {
      console.log("groupResponse", groupResponse)
      setGroupDetailData({
        loading: false,
        group: groupResponse
      })
    })

  const reloadClients = () =>
    clientService
      .getClients()
      .then(clientsResponse => setClients(clientsResponse))

  const reloadClientRoles = async (clientId: string | null) => {
    if(clientId) {
      setClientRoles([])
      const clientRoleResponse = await clientRoleService
        .getClientRoles(clientId)
      const assignedClientRoles = (
        selectedGroup
        && await groupService.getClientRoles(selectedGroup, clientId)
      ) || []

      setClientRoles(clientRoleResponse)
      setCurrentActiveClientRoles(assignedClientRoles)
      setSelectedClientRoles(assignedClientRoles)
    } else {
      setClientRoles([])
      setCurrentActiveClientRoles([])
      setSelectedClientRoles([])
    }
  }

  const reloadPage = async () => {
    await reloadClients()
    await reloadGroup()
  }

  const onAddGroup = () => { setAddGroupModalVisible(true) }

  const onEditGroup = () => { setShowEditGroupDialog(true) }

  const handleCreateGroup = async (groupData: GroupInfoProp) => {
    const { name, description, notes } = groupData
    try {
      await groupService
        .createGroup({
          parentId: selectedGroup || groupId,
          name,
          description,
          notes,
        })
        .then(() => message.success("Success"))
      setAddGroupModalVisible(false)
      await reloadGroup()
    } catch (e) {
      message.error("Create group failed")
    }
  }

  const handleEditGroup = async (groupId: string, groupData: GroupData) => {
    const response = await groupService.updateGroup(groupId, groupData)
    if(response==="success"){
      notification.success({
        message: "Success"
      })
      await reloadGroup()
      setShowEditGroupDialog(false)
    }else{
      notification.error({
        message: "Error"
      })
    }
  }

  const handleDeleteGroup = async (groupId: string) => {
    try {
      await groupService.deleteGroup(groupId)
      await reloadGroup()
    } catch (e) {
      message.error("Delete group failed")
    }
  }

  const onClientChange = async (clientId: string | null) => {
    setSelectedClientRoles([])
    setSelectedClient(clientId)
    await reloadClientRoles(clientId)
  }

  const onSaveClientRoles = async (clientId: string, clientRoles: string[]) => {
    selectedGroup && await groupService
      .setClientRoles(
        selectedGroup,
        clientId,
        clientRoles
      )
  }

  useEffect(() => {
    reloadPage()
  }, [])

  return (
    <>
      <Row>
        <Col>
          <Title>Group Detail</Title>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Card>

            <Row gutter={16}>
              <Col span={ (selectedGroup && 12 ) || 24 }>
                <Card>
                  <GroupTree
                    group={groupDetailData.group}
                    onSelect={async (selectedGroupTreeId) => {
                      await onClientChange(null)
                      setSelectedGroup(selectedGroupTreeId)
                      const foundGroup = findGroup(
                        groupDetailData.group,
                        selectedGroupTreeId || groupId
                      ) || { name: "", description: "", notes: "" }
                      setGroupInfo(foundGroup)
                    }}/>
                </Card>
              </Col>

              { selectedGroup && groupInfo
                ? <Col span="12">
                  <Card
                    title="Group Detail"
                    extra={
                      <>
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={onAddGroup}
                          type="primary"
                          size="small"
                        >
                        +Add SubGroup
                        </Button>
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={onEditGroup}
                          type="primary"
                          size="small"
                        >
                          Edit Group
                        </Button>
                        <Popconfirm
                          title="Are you sure you want to delete group"
                          onConfirm={() => handleDeleteGroup(selectedGroup)}>
                          <Button
                            style={{ marginLeft: 10 }}
                            type="primary"
                            size="small"
                            danger
                          >
                            Delete Group
                          </Button>
                        </Popconfirm></>
                    }>
                    <GroupInfo
                      groupInfo={groupDetailData.group as any}
                      clients={clients}
                      selectedClient={selectedClient}
                      onClientChange={onClientChange}
                      clientRoles={clientRoles}
                      currentActiveClientRoles={currentActiveClientRoles}
                      selectedClientRoles={selectedClientRoles}
                      onSelectClientRoles={setSelectedClientRoles}
                      onSave={onSaveClientRoles}
                    />
                  </Card></Col>
                : <></>
              }
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Add Group"
        visible={addGroupModalVisible}
        onCancel={() => setAddGroupModalVisible(false)}
        footer={null}
      >
        <EditGroup
          submitLabel="Create"
          initialValue={{ name: "", description: "", notes: "" }}
          onConfirm={handleCreateGroup}
        />
      </Modal>
      <Modal
        title="Edit Group"
        visible={showEditGroupDialog}
        onCancel={() => setShowEditGroupDialog(false)}
        footer={null}
      >
        {
          (selectedGroup && <EditGroup
            submitLabel="Update"
            initialValue={groupInfo || undefined}
            onConfirm={(groupData) => handleEditGroup(selectedGroup, groupData)}
          />) || <></>
        }
      </Modal>
    </>
  )
}
