import React, {useEffect} from "react"
import {Button, Form, Input} from "antd"

type PasswordData = {
  password: string
  rePassword: string
}

type Props = {
  onSubmit: (password: string) => void
}

export const PasswordForm: React.FC<Props> = ({
  onSubmit,
}): React.ReactElement => {
  const [form] = Form.useForm<PasswordData>()

  useEffect(() => {
    form.resetFields()
  }, [])

  const onFormSubmit = (passwordData: PasswordData) => {
    onSubmit(passwordData.password)
  }

  const layout = {}
  const buttonLayout = {}

  return <Form form={form} onFinish={onFormSubmit} { ...layout }>
    <Form.Item
      name="password"
      label="Password"
      rules={[
        { required: true, message: "Please input your password"}
      ]}
    >
      <Input.Password/>
    </Form.Item>
    <Form.Item
      name="rePassword"
      label="Re-Password"
      rules={[
        {
          required: true,
          message: "Please confirm your password"
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if(!value || getFieldValue("password") === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error("Password not matched"))
          }
        })
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item {...buttonLayout}>
      <Button type="primary" htmlType="submit">Submit</Button>
    </Form.Item>
  </Form>
}