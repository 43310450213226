import {v4 as uuidv4} from "uuid"
import {AxiosInstance} from "axios"
import dayjs from "dayjs"

export enum OrganizationStatus {
  Pending= "pending",
  Active = "active",
  Deleted = "deleted",
}

export enum LoginSettingType {
  Classic = "classic",
  DeviceVerification = "device-verification",
}

export type OrganizationData = {
  name: string
  description: string
  email: string
  username: string
  status: OrganizationStatus
  password: string
  employeeId: string
  loginSettings: { type: LoginSettingType }[]
}

export type Organization = OrganizationData & {
  id: string
  createdDate: dayjs.Dayjs
  updatedDate?: dayjs.Dayjs
}

type OrganizationItemResponse = {
  id: string
  name: string
  description: string
  email: string
  status: string
  employeeId: string
  password: string
  createdDate: string
}

export const OrganizationService = (axiosInstance: AxiosInstance) => ({
  getOrganizations: async (): Promise<Organization[]> =>
    axiosInstance
      .get("/auth24-admin/api/v1/organizations", {
        baseURL: "http://localhost:8080",
      })
      .then(result => result.data.map((organizationResponse: OrganizationItemResponse) => {
        const {id, name, description, email, status, employeeId, createdDate, password} = organizationResponse
        return ({id, name, description, email, status, employeeId, createdDate, password})
      }))
      .catch(error => console.log("error", error)),
  createOrganizations: async (organizationData: OrganizationData) : Promise<string> => {
    const result = await axiosInstance
      .post("/auth24-admin/api/v1/organizations",{
        name: organizationData.name,
        password: organizationData.password,
        description: organizationData.description,
        email: organizationData.email,
        status: OrganizationStatus.Active,
        employeeId: "",
        loginSettings: organizationData.loginSettings,
      },{
        baseURL: "http://localhost:8080",
      })
    const {status= 404} = result
    if(status === 201){
      return "success"
    }
    return "error"
  },
  updateOrganizations: async (organizationId: string,request: any) : Promise<string> => {
    const requests : any  = {
      name : request.name,
      password : request.password,
      description : request.description,
      email : request.email,
      status : "pending",
      employeeId: "",
      updateDate: dayjs()
    }
    const result = await axiosInstance.put(`/auth24-admin/api/v1/organizations/${organizationId}`,
      requests, {
        baseURL: "http://localhost:8080",
      })
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  },
  deleteOrganizations: async (organizationId: string) : Promise<string> => {
    const result = await axiosInstance.delete(`/auth24-admin/api/v1/organizations/${organizationId}`,{
      baseURL: "http://localhost:8080",
    })
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  }
})
