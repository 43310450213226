import {AuditLogEventType} from "./audit-log-event-type"
import {AuditLogCommon} from "./audit-log-common"

export type LoginFailAuditLog = AuditLogCommon & LoginFailAuditLogData

export type LoginFailAuditLogData = {
  activity: AuditLogEventType.LoginFailed
  info: LoginFailInfo
  message: string
}

export type LoginFailInfo =
  LoginFailRedirectUrl |
  LoginFailUsernameNotFound |
  LoginFailInvalidPassword |
  LoginFailUserInactive |
  LoginFailLDAPAuthenticationFailed

export type LoginFailRedirectUrl = {
  reason: LoginFailReason.InvalidRedirectUrl
  redirectUrl: string
  clientId: string
  organizationId: string
}

export type LoginFailUsernameNotFound = {
  reason: LoginFailReason.UsernameNotFound
  username: string
  clientId: string
  organizationId: string
}

export type LoginFailInvalidPassword = {
  reason: LoginFailReason.InvalidPassword
  userId: string
  clientId: string
  organizationId: string
}

export type LoginFailUserInactive = {
  reason: LoginFailReason.UserInactive
  userId: string
  clientId: string
  organizationId: string
}

export type LoginFailLDAPAuthenticationFailed = {
  reason: LoginFailReason.LDAPAuthenticationFailed
  username: string
  clientId: string
  organizationId: string
}

export enum LoginFailReason {
  InvalidRedirectUrl = "invalid-redirect-url",
  UsernameNotFound = "username-not-found",
  UserInactive = "user-inactive",
  LDAPAuthenticationFailed = "ldap-authentication-failed",
  InvalidPassword = "invalid-password",
}