import React from "react"
import {Modal} from "antd"
import {UpdateOrganizationUnitsForm} from "./UpdateOrganizationUnitsForm"

type Props = {
  visible: boolean,
  initialLdapQuery?: string
  onUpdateLdapQuery: (ldapQuery: string) => void,
  onClose: () => void
}

export const UpdateLdapQueryDialog: React.FC<Props> = ({
  visible,
  onClose,
  initialLdapQuery,
  onUpdateLdapQuery,
}) => <Modal
  title="Update LDAP Query"
  visible={visible}
  onCancel={onClose}
  footer={null}>
  <UpdateOrganizationUnitsForm
    initialLdapQuery={initialLdapQuery}
    onSubmit={onUpdateLdapQuery}
  />
</Modal>