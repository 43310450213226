import {AxiosInstance} from "axios"
import {UserDevice} from "../screens/main/screens/components/models/user-device"


export const UserDeviceService = (axiosInstance: AxiosInstance) => {
  const apiBaseUrl = "/organization-admin/api/v1/user-devices"
  return {
    getUserDevices: (userId: string): Promise<UserDevice[]> =>
      axiosInstance.get(`${apiBaseUrl}?userId=${userId}`)
        .then(response => response.data as UserDevice[]),
    deleteUserDevice: (clientId: string, userId: string) =>
      axiosInstance
        .delete(`${apiBaseUrl}?clientId=${clientId}&userId=${userId}`)
  }
}