import {AxiosInstance} from "axios"

export type ClientRole = {
  id: string
  name: string
  description: string
}

export const ClientRoleService = (axiosInstance: AxiosInstance) => ({
  getClientRoles: async (clientId: string): Promise<ClientRole[]> =>
    axiosInstance
      .get(`/organization-admin/api/v1/clients/${clientId}/roles`)
      .then(result => result.data.map((clientRoles: ClientRole) => {
        const { id, name, description} = clientRoles
        return { id, name, description }
      }))
      .catch(error => console.log("error", error)),
  setClientRoles: async (clientId: string,request: any) : Promise<string> => {
    const requests : any  = {
      name: request.name,
      description: request.description,
    }
    const result = await axiosInstance
      .post(`/organization-admin/api/v1/clients/${clientId}/roles`, requests)
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  },
  deleteClientRoleById: async (clientId: string, roleId: string) : Promise<string> => {
    const result = await axiosInstance
      .delete(`/organization-admin/api/v1/clients/${clientId}/roles/${roleId}`)
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  },
  updateClientRoleById: async (clientId: string, roleId: string, request: any) : Promise<string> => {
    const requests : any  = {
      name: request.name,
      description: request.description,
    }
    const result = await axiosInstance
      .put(`/organization-admin/api/v1/clients/${clientId}/roles/${roleId}`, requests)
    const {status= 404} = result
    if(status === 200){
      return "success"
    }
    return "error"
  }
})
