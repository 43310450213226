import React, {useEffect, useState} from "react"
import {Card, Col, message, Row, Typography} from "antd"
import {AuditLog} from "../../../models/audit-logs/audit-log"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {AuditLogService} from "../../../services/audit-log-service"
import {AuditLogTable} from "./components/AuditLogTable"
import {useNavigate} from "react-router-dom"

export const AuditLogs: React.FC = (): React.ReactElement => {
  const { id24Axios } = useId24()
  const navigate = useNavigate()
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([])
  const [totalAuditLogs, setTotalAuditLogs] = useState<number>(0)
  const [hasMoreAuditLogs, setHasMoreAuditLogs] = useState(true)
  const limit = 10

  const auditLogService = AuditLogService(id24Axios())

  const loadPage = (offset: number, limit: number) => {
    hasMoreAuditLogs && auditLogService
      .getAuditLogs(limit, offset)
      .then(paginatedAuditLogs => {
        const { data: newAuditLogs, total } = paginatedAuditLogs
        setAuditLogs(newAuditLogs)
        setTotalAuditLogs(total)
      })
      .catch(_error => {
        setHasMoreAuditLogs(false)
        return message.error("Load audit log failed")
      })
  }

  useEffect(() => loadPage(0, limit), [])
  return <>
    <Row>
      <Col>
        <Typography.Title>Audit Logs</Typography.Title>
      </Col>
    </Row>

    <Row>
      <Col span="24">
        <Card>
          <AuditLogTable
            navigator={{
              goToClient: (clientId: string) => navigate(`/main/clients/${clientId}/detail`),
              goToUser: (userId: string) => navigate(`/main/users/${userId}/detail`)
            }}
            auditLogs={auditLogs}
            totalAuditLogs={totalAuditLogs}
            loadPage={(page, pageSize) => loadPage((page - 1) * pageSize, pageSize)}
          />
        </Card>
      </Col>
    </Row>
  </>
}

