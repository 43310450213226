import React from "react"
import {Navigate, Route, Routes} from "react-router-dom"
import {Login} from "./welcome/Login"
import {Main} from "./main/Main"
import {ForgetPassword} from "./welcome/ForgetPassword"
import {ResetPassword} from "./welcome/ResetPassword"
import {ResetPasswordComplete} from "./welcome/ResetPasswordComplete"
import {Id24Provider} from "../drivers/id24/Id24Provider"
import {loadAppConfig} from "../config/app-config"
import {ResetPasswordEmailSent} from "./welcome/ResetPasswordEmailSent"
import {UnauthorizedPage} from "./welcome/UnauthorizedPage"

export const RootRouter: React.FC = (): React.ReactElement => {
  const { apiBaseUrl, loginOptions, loginPage } = loadAppConfig()
  const id24ConfigV2 = {
    refreshTokenIntervalInSeconds: 50,
    resourceApiBaseUrl: apiBaseUrl
  }

  return <Routes>
    <Route path="/" element={<Navigate replace to="/main"/>}/>
    <Route path="/login" element={<Login loginOptions={loginOptions} loginPageTitle={loginPage}/>}/>
    <Route path="/unauthorized" element={<Id24Provider config={id24ConfigV2}><UnauthorizedPage/></Id24Provider>}/>
    <Route path="/forget-password" element={<ForgetPassword loginPageTitle={loginPage}/>}/>
    <Route path="/reset-password-email-sent" element={<ResetPasswordEmailSent/>}/>
    <Route path="/reset-password" element={<ResetPassword loginPageTitle={loginPage}/>}/>
    <Route path="/reset-password-completed" element={<ResetPasswordComplete/>}/>
    <Route path="/main/*" element={<Id24Provider config={id24ConfigV2}><Main/></Id24Provider>}/>
  </Routes>
}

