import React from "react"
import {Modal} from "antd"
import {ClientFormData, EditClientForm} from "../screens/components/EditClientForm"
import {LoginOption} from "../../../models/client"

type Props = {
  allowOptions: LoginOption[]
  visible: boolean
  onCancel: () => void
  editingClient: ClientFormData
  onEdit: (clientData: ClientFormData) => void
}

export const EditClientDialog: React.FC<Props> = ({
  allowOptions,
  visible,
  editingClient,
  onCancel,
  onEdit,
}): React.ReactElement =>
  <Modal
    title="Edit Client"
    visible={visible}
    centered
    onCancel={onCancel}
    footer={null}
  >
    <EditClientForm
      allowedOptions={allowOptions}
      submitLabel="Create"
      initialValue={editingClient}
      onConfirm={onEdit}
    />
  </Modal>