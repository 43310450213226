import React, {useEffect} from "react"
import {Button, Form, Input, Select} from "antd"
import {ClientType, LoginOption} from "../../../../models/client"

export type ClientFormData = {
  name: string
  description: string
  type: ClientType
  redirectUrls: string
  loginOption: LoginOption
}

interface Props {
  allowedOptions: LoginOption[]
  initialValue: ClientFormData | null
  submitLabel: string
  onConfirm: (clientData: ClientFormData) => void
}

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16}
}

const tailLayout = {
  wrapperCol: {offset: 8, span: 16},
}
const { Option } = Select

export const EditClientForm: React.FC<Props> = ({
  allowedOptions,
  initialValue,
  submitLabel,
  onConfirm
}): React.ReactElement => {
  const [form] = Form.useForm<ClientFormData>()
  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(initialValue || {})
  }, [initialValue])
  return (
    <Form {...layout} form={form} colon={false} onFinish={onConfirm}>
      <Form.Item name="name" label="Name"
        rules={[{required: true, message: "Name is required."}]}>
        <Input/>
      </Form.Item>
      <Form.Item name="description" label="Description"
        rules={[{required: true, message: "Description is required."}]}
      >
        <Input/>
      </Form.Item>

      <Form.Item name="type" label="Type">
        <Select disabled={true} defaultValue={ClientType.Public}>
          <Option value={ClientType.Public}>Public</Option>
          <Option value={ClientType.Confidential}>Public</Option>
        </Select>
      </Form.Item>

      <Form.Item name="loginOption" label="Login Option">
        <Select defaultValue={allowedOptions[0]}>
          {
            allowedOptions.map(option => {
              switch (option) {
              case LoginOption.Password:
                return <Option value={LoginOption.Password}>Password</Option>
              case LoginOption.PasswordWithDevice:
                return <Option value={LoginOption.PasswordWithDevice}>Password with device</Option>
              case LoginOption.DeviceId:
                return <Option value={LoginOption.DeviceId}>Device Only</Option>
              default:
                return <></>
              }
            })
          }
        </Select>
      </Form.Item>

      <Form.Item name="redirectUrls" label="Redirect Url">
        <Input/>
      </Form.Item>

      <Form.Item
        {...tailLayout}
      >
        <Button type="primary" htmlType="submit">
          {submitLabel}
        </Button>
      </Form.Item>

    </Form>
  )
}
