import React, {useEffect} from "react"
import {Button, Result} from "antd"
import {useId24} from "../../drivers/id24/Id24Provider"
import {AccessReader} from "../../drivers/id24/access-reader"
import {useNavigate} from "react-router-dom"
import {SideMenuGroup} from "../main/components/side-menu/SideMenu"

export const UnauthorizedPage: React.FC = () => {
  const { authenticated, logout, login, tokenAccess } = useId24()
  const navigate = useNavigate()

  const onLogout = () => {
    console.log("Log out")
    logout()
      .then(() => login(window.location.href, false))
  }

  useEffect(() => {
    const sideMenuGroups = (tokenAccess && AccessReader(tokenAccess.userAccess).getMenuSet()) || []
    if(sideMenuGroups.length > 0) {
      const [sideMenuGroup] = sideMenuGroups
      if(sideMenuGroup === SideMenuGroup.OrganizationAdmin) {
        navigate("/main/users")
      } else {
        navigate("/main/organizations")
      }
    }
  }, [tokenAccess])

  if(!authenticated) {
    login(window.location.href, false)
    return <></>
  }

  return <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page. Please contact your administrator to grant you access"
    extra={<Button type="primary" onClick={onLogout}>Log out</Button>}
  />
}