import {LoginOption} from "../models/login-option"

export enum NodeEnvironment {
  Production = "production",
  Development = "development",
}

export interface AppConfig {
  nodeEnvironment: NodeEnvironment
  appVersion: string
  apiBaseUrl: string
  authUrl: string
  authServer: string
  clientId: string
  organizationId: string
  customerLogo: string
  usm: string
  defaultLogin: string
  language?: string
  loginOptions: LoginOption[]
  loginPage: {
    title: string
  }
}

export const loadAppConfig = (): AppConfig => {
  const {
    NODE_ENV,
    REACT_APP_VERSION,
    REACT_APP_API_BASE_URL,
    REACT_APP_AUTH_URL,
    REACT_APP_AUTH_SERVER,
    REACT_APP_CUSTOMER_LOGO,
    REACT_APP_DEFAULT_LOGIN,
    REACT_APP_ID24_CLIENT_ID,
    REACT_APP_ID24_ORGANIZATION_ID,
    REACT_APP_LOGIN_OPTIONS,
    REACT_APP_LOGIN_PAGE_TITLE,
  } = process.env

  if (
    NODE_ENV &&
    REACT_APP_VERSION &&
    REACT_APP_API_BASE_URL &&
    REACT_APP_AUTH_URL &&
    REACT_APP_AUTH_SERVER &&
    REACT_APP_ID24_CLIENT_ID &&
    REACT_APP_ID24_ORGANIZATION_ID &&
    REACT_APP_LOGIN_OPTIONS &&
    REACT_APP_LOGIN_PAGE_TITLE
  ) {
    const nodeEnvironment = NODE_ENV !== "development" && NODE_ENV !== "test"
      ? NodeEnvironment.Production
      : NodeEnvironment.Development

    const supportOptions = [LoginOption.Local, LoginOption.Ldap, LoginOption.LdapWithQuery]
    const userConfigLoginOption = `${REACT_APP_LOGIN_OPTIONS}`
    const loginOptionConfig: LoginOption[] = (userConfigLoginOption.length > 0 ? userConfigLoginOption : "local,ldap")
      .split(",")
      .filter(option => supportOptions.includes(option.toLowerCase() as LoginOption))
      .map(option => {
        switch (option.toLowerCase()) {
        case LoginOption.Ldap:
          return LoginOption.Ldap
        case LoginOption.LdapWithQuery:
          return LoginOption.LdapWithQuery
        default:
          return LoginOption.Local
        }
      })
    const appConfig: AppConfig = {
      nodeEnvironment,
      appVersion: REACT_APP_VERSION,
      language: "TH",
      apiBaseUrl: REACT_APP_API_BASE_URL,
      authUrl: REACT_APP_AUTH_URL || "http://localhost/api/auth",
      authServer: REACT_APP_AUTH_SERVER,
      clientId: REACT_APP_ID24_CLIENT_ID,
      organizationId: REACT_APP_ID24_ORGANIZATION_ID,
      customerLogo: REACT_APP_CUSTOMER_LOGO || "gsb",
      usm: process.env.REACT_APP_USM_CLIENT_ID || "d9717db5-981e-4554-80d2-67eadcedbedd",
      defaultLogin: REACT_APP_DEFAULT_LOGIN || "ADFS",
      loginOptions: loginOptionConfig,
      loginPage: {
        title: REACT_APP_LOGIN_PAGE_TITLE
      }
    }
    return appConfig
  }
  throw new Error("Invalid configuration")
}
