import React from "react"
import {Avatar, Col, Dropdown, Layout, Menu, Row, Space} from "antd"
import {CaretDownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from "@ant-design/icons"

const {Header} = Layout

export enum MainMenuItem {
  Profile = "profile",
  Logout = "logout",
}

type Props = {
  collapse: boolean
  onCollapse: (collapse: boolean) => void
  username: string
  onMenuClicked: (mainMenuItem: MainMenuItem) => void
}

export const MainHeader: React.FC<Props> = ({collapse, onCollapse, username, onMenuClicked}): React.ReactElement => {
  const menu = (
    <Menu>
      <Menu.Item key="profile" onClick={() => onMenuClicked(MainMenuItem.Profile)}>Profile</Menu.Item>
      <Menu.Item key="logout" onClick={() => onMenuClicked(MainMenuItem.Logout)}>Logout</Menu.Item>
    </Menu>
  )
  return <Header className="header-layout" style={{backgroundColor: "white", padding: 0, textAlign: "left"}}>
    <Row>
      <Col>
        {
          collapse
            ? <MenuUnfoldOutlined className="trigger" onClick={() => onCollapse(false)}/>
            : <MenuFoldOutlined className="trigger" onClick={() => onCollapse(true)}/>
        }
      </Col>
      <Col style={{marginRight: 5, alignItems: "center", flex: 1, textAlign: "right"}}>
        <Space>
          <Avatar size="large" icon={<UserOutlined/>}/>
          <span>{username}</span>
        </Space>
      </Col>
      <Col style={{marginRight: 20}}>
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <CaretDownOutlined/>
          </a>
        </Dropdown>
      </Col>
    </Row>
  </Header>
}