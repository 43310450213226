import {AxiosInstance} from "axios"
import {LoginSettingType} from "./organization-service"

export type MyOrganization = {
  name: string
  loginSettings: { type: LoginSettingType }[]
}

export type IMyOrganizationService = {
  getMyOrganization: () => Promise<MyOrganization>
}

export const MyOrganizationService = (axiosInstance: AxiosInstance): IMyOrganizationService => {
  const apiBaseUrl = "/organization-admin/api/v1/my-organization"
  return {
    getMyOrganization: async () =>
      axiosInstance
        .get(`${apiBaseUrl}`)
        .then(response => response.data)
  }
}