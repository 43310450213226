import React from "react"

import "./Login.css"

import {WelcomeAccountPage} from "./components/WelcomeAccountPage"
import {ForgetPasswordForm} from "./components/ForgetPasswordForm"
import {useId24} from "../../drivers/id24/Id24Provider"
import {MyAccountService} from "../../services/my-account-service"
import {loadAppConfig} from "../../config/app-config"
import {useNavigate} from "react-router-dom"

type Props = {
  loginPageTitle: {
    title: string
  }
}

export const ForgetPassword: React.FC<Props> = ({loginPageTitle}): React.ReactElement => {
  const { id24Axios } = useId24()
  const { apiBaseUrl, clientId } = loadAppConfig()
  const navigate = useNavigate()

  const myAccountService = MyAccountService(id24Axios(apiBaseUrl))

  const onRequestResetPassword = (email: string) => {
    myAccountService
      .requestResetPassword(clientId, email)
      .then(() =>
        navigate("/reset-password-email-sent")
      )
  }
  return <WelcomeAccountPage>
    <ForgetPasswordForm onSubmit={onRequestResetPassword} loginPageTitle={loginPageTitle}/>
  </WelcomeAccountPage>
}