import React, {useEffect} from "react"
import {Button, Form, Input} from "antd"

export type LDAPUserData = {
  username: string
  email: string
  ldapQuery: string
}

type FormLDAPUserData = {
  username: string
  email: string
  ldapQuery: string
}

type EProps = {
  onSubmit: (userData: LDAPUserData) => void
  onValidateUsername: (username: string) => Promise<string | undefined>
  onValidateEmail: (email: string) => Promise<string | undefined>
  loading: boolean
}

export const EditLDAPUser: React.FC<EProps> = ({
  onSubmit,
  onValidateUsername,
  onValidateEmail,
  loading
}): React.ReactElement => {
  const [form] = Form.useForm<FormLDAPUserData>()

  useEffect(() => {
    form.resetFields()
  }, [])

  return <Form form={form} onFinish={({username, email, ldapQuery}) => onSubmit({ username, email, ldapQuery})}>
    <Form.Item
      name="email"
      label="Email"
      rules={[
        { required: true, message: "Email is required."},
        { type: "email", message: "This input is not a valid email address" },
        { validator: async (_, value) => {
          const validationFailReason = await onValidateEmail(value)
          if(validationFailReason) {
            throw new Error(validationFailReason)
          }
        }}
      ]}
    >
      <Input/>
    </Form.Item>
    <Form.Item
      name="username"
      label="Username"
      rules={[
        {required: true, message: "Username is required."},
        {validator: async (_, value) => {
          const validationFailReason = await onValidateUsername(value)
          if(validationFailReason) {
            throw new Error(validationFailReason)
          }
        }}
      ]}
    >
      <Input/>
    </Form.Item>
    <Form.Item
      name="ldapQuery"
      label="LDAP Query"
    >
      <Input placeholder="CN=John Smith,OU=User,DC=example"/>
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Add
      </Button>
    </Form.Item>
  </Form>
}