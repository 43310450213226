import {GroupWithSubgroups} from "../../../../services/group-service"

type Optional<T> = T | undefined

export const findParentGroup = (
  predicate: (groupWithSubGroups: GroupWithSubgroups) => boolean,
  groupTree: GroupWithSubgroups[]
): Optional<GroupWithSubgroups>  =>
  groupTree
    .reduce<Optional<GroupWithSubgroups>>(
      (prev, group) => {
        if (prev) {
          return prev
        }
        if (group.subGroups.find(predicate) !== undefined) {
          return group
        }
        return findParentGroup(predicate, group.subGroups)
      },
      undefined)

export const findParentGroups = (
  predicate: (group: GroupWithSubgroups) => boolean,
  groupTree: GroupWithSubgroups[]
): GroupWithSubgroups[] =>
  groupTree
    .reduce<GroupWithSubgroups[]>(
      (prev, group) =>
        [
          ...prev,
          ...(group.subGroups.find(predicate) !== undefined ? [group] : []),
          ...findParentGroups(predicate, group.subGroups)
        ],
      [])

export const filterGroups = (
  predicate: (group: GroupWithSubgroups) => boolean,
  groupTree: GroupWithSubgroups[]
): GroupWithSubgroups[] =>
  groupTree
    .filter(group => predicate(group) || filterGroups(predicate, group.subGroups).length > 0)