import {GroupWithSubgroups} from "../../../../services/group-service"

type GroupTree = {
  title: string
  value: string
  key: string
  children: GroupTree[]
}

type GroupTreeWithT<T> = GroupTree & T

export const groupWithSubGroupsToGroupTree = (group: GroupWithSubgroups): GroupTree => ({
  title: group.name,
  value: group.id,
  key: group.id,
  children: group.subGroups.map(groupWithSubGroupsToGroupTree)
})

export const groupWithSubGroupsToSelectableGroupTree =
  <T>(selectedGroups: string[]) => (group: GroupWithSubgroups, mapper: (found: boolean) => T): GroupTreeWithT<T> => {
    return {
      ...groupWithSubGroupsToGroupTree(group),
      ...mapper(selectedGroups.includes(group.id)),
      children: group.subGroups.map(subGroup => groupWithSubGroupsToSelectableGroupTree(selectedGroups)(subGroup, mapper))
    }
  }