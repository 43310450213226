import {AxiosInstance} from "axios"
import {AuditLog} from "../models/audit-logs/audit-log"
import dayjs from "dayjs"

export type Paginated<T> = {
  data: T[],
  total: number,
}

export const AuditLogService = (axiosInstance: AxiosInstance) => ({
  getAuditLogs: (limit: number, offset: number): Promise<Paginated<AuditLog>> =>
    axiosInstance
      .get(`/organization-admin/api/v1/audit-logs?limit=${limit}&offset=${offset}`)
      .then(response => {
        const auditLogs = response.data as AuditLog[]
        const transformedAuditLog = auditLogs.map(auditLog => ({
          ...auditLog,
          createdAt: dayjs(auditLog.createdAt),
        }))
        const total = parseInt(response.headers["x-total-count"])
        return {
          data: transformedAuditLog,
          total,
        }
      })
})