import {AxiosInstance} from "axios"
import {UserType} from "./user-service"

export const RegistrationService = (axiosInstance: AxiosInstance) => {
  const apiBaseUrl = "/registration/api/v1"
  return {
    validateUserName: (username: string, type: UserType): Promise<string | undefined> =>
      axiosInstance
        .post(`${apiBaseUrl}/check-id`, { username, type })
        .then(response => response.data.message),
    validateUserEmail: (email: string, type: UserType): Promise<string | undefined> =>
      axiosInstance
        .post(`${apiBaseUrl}/check-id`, { email, type })
        .then(response => response.data.message),
  }
}