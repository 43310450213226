import {GroupWithSubgroups} from "../../../../services/group-service"
import {DataNode} from "antd/lib/tree"
import {CarryOutOutlined} from "@ant-design/icons"
import React from "react"

export const groupWithSubGroupsToGroupTree = (groupWithSubGroups: GroupWithSubgroups): DataNode => ({
  title: groupWithSubGroups.name,
  key: groupWithSubGroups.id,
  icon:  <CarryOutOutlined />,
  children: (
    groupWithSubGroups.subGroups.length > 0
    && groupWithSubGroups.subGroups.map(groupWithSubGroupsToGroupTree)
  ) || undefined
})