import React, {useEffect, useState} from "react"
import {Button, Card, Col, Modal, Row, Typography, notification} from "antd"
import {GroupTable} from "./components/GroupTable"
import {EditGroup, GroupData} from "./components/EditGroup"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {GroupService, GroupWithId} from "../../../services/group-service"
import {WarningOutlined} from "@ant-design/icons"
import {useNavigate} from "react-router-dom"

const { Title } = Typography

export const GroupManagement: React.FC = (): React.ReactElement => {
  const { id24Axios } = useId24()
  const navigate = useNavigate()
  const [groupList, setGroupList] = useState<GroupWithId[]>([])
  const [editingGroup, setEditingGroup] = useState<GroupData|null>()
  const [groupId, setGroupId] = useState<string>("")
  const [showEditGroupDialog, setShowEditGroupDialog] = useState<boolean>(false)
  const [showAddGroupDialog, setShowAddGroupDialog] = useState<boolean>(false)
  const [visibleModalConfirmDelete, setVisibleModalConfirmDelete] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const onEditGroup = (id: string, group: GroupData) => {
    setGroupId(id)
    setEditingGroup(group)
    setShowEditGroupDialog(true)
  }
  const navigateToGroupDetail = (group: GroupWithId) =>
    navigate(`/main/groups/${group.id}/subGroups`,{
      state: {
        detail: group,
      }
    })
  const onDeleteGroup = (roleId: string) => {
    setGroupId(roleId)
    setVisibleModalConfirmDelete(true)
  }
  const onAddGroup = () => {
    setShowAddGroupDialog(true)
  }

  const groupService = GroupService(id24Axios())

  useEffect(() => {
    reloadGroupList()
  }, [])
  const reloadGroupList = () => {
    setLoading(true)
    groupService
      .getGroups()
      .then(groupList => {
        setGroupList(groupList)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        notification.error({
          message: "error"
        })
      })
  }

  const handleEditGroup = async (groupId: string, groupData: GroupData) => {
    const response = await groupService.updateGroup(groupId, groupData)
    if(response==="success"){
      notification.success({
        message: "Success"
      })
      reloadGroupList()
    }else{
      notification.error({
        message: "Error"
      })
    }
    setShowEditGroupDialog(false)
  }

  const handleAddGroup = async (groupData: GroupData) => {
    const response = await groupService.createGroup(groupData)
    if(response==="success"){
      notification.success({
        message: "Success"
      })
      reloadGroupList()
    }else{
      notification.error({
        message: "Error"
      })
    }
    setShowAddGroupDialog(false)
  }
  const deleteGroup = async () => {
    await groupService.deleteGroup(groupId as string)
    reloadGroupList()
    setVisibleModalConfirmDelete(false)
  }
  return (
    <>
      <Row>
        <Col>
          <Title>Group Management</Title>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Card>
            <Row justify="end">
              <Col>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={onAddGroup}
                  type="primary"
                >
                  +Add Group
                </Button>
              </Col>
            </Row>
            <br/>
            <Modal
              title="Edit Group"
              visible={showEditGroupDialog}
              onCancel={() => setShowEditGroupDialog(false)}
              footer={null}
            >
              {
                (editingGroup && <EditGroup
                  submitLabel="Update"
                  initialValue={editingGroup}
                  onConfirm={(groupData) => handleEditGroup(groupId, groupData)}
                />) || <></>
              }
            </Modal>
            <Modal
              title="Add Group"
              visible={showAddGroupDialog}
              onCancel={() => setShowAddGroupDialog(false)}
              footer={null}
            >
              <EditGroup
                submitLabel="Create"
                onConfirm={handleAddGroup}
              />
            </Modal>
            <Modal
              title={null}
              visible={visibleModalConfirmDelete}
              onCancel={() => setVisibleModalConfirmDelete(false)}
              footer={null}
            >
              <div className="modal-content">
                <div className="modal-content-body">
                  <p>Do you want to Delete group this cannot be undone, and will fail if this group has subgroups</p>
                </div>
                <div>
                  <Button onClick={() => setVisibleModalConfirmDelete(false)}>Cancel</Button>
                  &nbsp;&nbsp;
                  <Button type="primary" onClick={deleteGroup}>Ok</Button>
                </div>
              </div>
            </Modal>

            <GroupTable
              groupList={groupList}
              loading={loading}
              onDelete={onDeleteGroup}
              onEdit={(id, editing) => onEditGroup(id, editing as any)}
              onSelect={navigateToGroupDetail}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}
