import React, {useEffect, useState} from "react"
import {Button, Card, Col, Input, message, Modal, notification, Row, Select, Space, Typography} from "antd"
import {useId24} from "../../../drivers/id24/Id24Provider"
import {ClientTable} from "./components/ClientTable"
import {ClientService} from "../../../services/client-service"
import {WarningOutlined} from "@ant-design/icons"
import {useNavigate} from "react-router-dom"
import {Client, ClientType, LoginOption} from "../../../models/client"
import {AddClientDialog} from "../components/AddClientDialog"
import {EditClientDialog} from "../components/EditClientDialog"
import {ClientFormData} from "./components/EditClientForm"
import {MyOrganizationService} from "../../../services/my-organization-service"
import {LoginSettingType} from "../../../services/organization-service"

const { Search } = Input
const { Title } = Typography
const { Option } = Select

const defaultEditingClient: ClientFormData = {
  name: "",
  description: "",
  type: ClientType.Public,
  redirectUrls: "",
  loginOption: LoginOption.Password,
}

export const ClientManagement: React.FC = (): React.ReactElement => {
  const { id24Axios } = useId24()
  const navigate = useNavigate()
  const [clients, setClients] = useState<Client[]>([])
  const [showAddClientDialog, setShowAddClientDialog] = useState<boolean>(false)
  const [showEditClientDialog, setShowEditClientDialog] = useState<boolean>(false)
  const [editingClient, setEditingClient] = useState<ClientFormData>(defaultEditingClient)
  const [selectOption, setSelectOption] = useState("id")
  const [allowedLoginOptions, setAllowedLoginOptions] = useState<LoginOption[]>([])

  const [visibleModalConfirmDelete, setVisibleModalConfirmDelete] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [clientId,setClientId]= useState<string>("")
  const onEditClient = (clientId: string, client: Client) => {
    setClientId(clientId)
    setEditingClient({
      name: client.name,
      description: client.description,
      type: client.info.type,
      redirectUrls: client.info.redirectUrls.join(";"),
      loginOption: client.loginOption,
    })
    setShowEditClientDialog(true)
  }
  const onDeleteClient = (clientId: string) => {
    setClientId(clientId)
    setVisibleModalConfirmDelete(true)
  }

  const clientService = ClientService(id24Axios())
  const myOrganizationService = MyOrganizationService(id24Axios())

  useEffect(() => {
    reloadClients()
    myOrganizationService
      .getMyOrganization()
      .then(async (organization) => {
        const mayBeDuplicatedLoginOptions = organization.loginSettings.map(loginSetting => {
          switch (loginSetting.type) {
          case LoginSettingType.Classic:
            return LoginOption.Password
          case LoginSettingType.DeviceVerification:
            return LoginOption.PasswordWithDevice
          default:
            return LoginOption.Password
          }
        })

        const processedLoginOptions: LoginOption[] = [
          ...(mayBeDuplicatedLoginOptions.includes(LoginOption.Password) ? [LoginOption.Password] : []),
          ...(mayBeDuplicatedLoginOptions.includes(LoginOption.PasswordWithDevice) ? [LoginOption.PasswordWithDevice] : []),
        ]
        setAllowedLoginOptions(processedLoginOptions)
      })
      .catch((error) => {
        message.error("Failed to load organization info")
      })
  }, [])
  const reloadClients = () => {
    setLoading(true)
    clientService
      .getClients()
      .then(clients => {
        setClients(clients)
        setLoading(false)
        // setEditingOrganization(null)
      }).catch(error => {
        setLoading(false)
        notification.error({
          message: "error"
        })
      })
  }
  const handleEditClient = async (client: ClientFormData) => {
    const response = await clientService.updateClient(clientId,client)
    if(response==="success"){
      notification.success({
        message: "success"
      })
      setEditingClient(defaultEditingClient)
      setShowEditClientDialog(false)
      reloadClients()
    } else {
      notification.error({
        message: "error"
      })
    }
  }
  const handleAddClient = (client: ClientFormData) =>
    clientService.createClient({
      name: client.name,
      description: client.description,
      info: {
        type: ClientType.Public,
        redirectUrls: client.redirectUrls.split(",")
      },
      loginOption: client.loginOption
    })
      .then(() => {
        notification.success({
          message: "success"
        })
        reloadClients()
        setShowAddClientDialog(false)
      })
      .catch(error =>
        notification.error({
          message: "error"
        }))

  const handleDeleteClient = async () => {
    const response = await clientService.deleteClient(clientId)
    if(response==="success"){
      notification.success({
        message: "success"
      })
      setVisibleModalConfirmDelete(false)
      reloadClients()
    }else{
      notification.success({
        message: "success"
      })
    }
  }
  return <>
    <Row>
      <Col>
        <Title>Client</Title>
      </Col>
    </Row>

    <Row>
      <Col span="24">
        <Card>
          <Row justify="end">
            <Col>
              <Space>
                <Select defaultValue="id" style={{ width: 120 }} onChange={(e) => setSelectOption(e)}>
                  <Option value="id">Id</Option>
                  <Option value="name">Name</Option>
                </Select>
                <Search allowClear />
              </Space>
            </Col>
            <Col>
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => setShowAddClientDialog(true)}
                type="primary"
                loading={allowedLoginOptions.length === 0}
              >
                +Add client
              </Button>
            </Col>
          </Row>
          <br />
          <EditClientDialog
            allowOptions={allowedLoginOptions}
            visible={showEditClientDialog}
            onCancel={() => setShowEditClientDialog(false)}
            editingClient={editingClient}
            onEdit={handleEditClient}
          />
          <AddClientDialog
            allowOptions={allowedLoginOptions}
            visible={showAddClientDialog}
            onCancel={() => setShowAddClientDialog(false)}
            onCreate={handleAddClient}
          />
          <Modal
            title={null}
            centered
            visible={visibleModalConfirmDelete}
            onCancel={() => setVisibleModalConfirmDelete(false)}
            footer={null}
          >
            <div className="modal-content">
              <div className="modal-content-body">
                <WarningOutlined className="icon-warning"/>
                <p>Do you want to Delete</p>
              </div>
              <div>
                <Button onClick={() => setVisibleModalConfirmDelete(false)}>Cancel</Button>
                &nbsp;&nbsp;
                <Button type="primary" onClick={handleDeleteClient}>Ok</Button>
              </div>
            </div>
          </Modal>
          <ClientTable
            clients={clients}
            onEdit={onEditClient}
            onDelete={onDeleteClient}
            onSelect={(client) => navigate(`/main/clients/${client.id}/detail`,{
              state: {
                detail: client,
              }
            })}
            loading={loading}/>
        </Card>
      </Col>
    </Row>
  </>
}
